import {apiFetch} from "../swClient";

export default async function getProductsByIds(req, productIds) {
	let productRes = await apiFetch([process.env.STOREFRONT_PRODUCT_ENDPOINT].join('/'), 'post', {
		ids: productIds,
		associations: {
			manufacturer: {}
		},
		includes: {
			product: [
				"media",
				"name",
				"productNumber",
				"customFields",
				"calculatedPrice",
				"calculatedPrices",
				"cover",
				"parentId",
				"id",
				"available",
				"translated",
				"optionIds",
				"properties",
				"manufacturer",
				"seoUrls",
				"seoCategory",
				"deliveryTime"
			],
			calculated_price: [
				"unitPrice",
				"quantity",
				"listPrice"
			],
			product_manufacturer: [
				"translated",
				"link",
				"name",
				"id"
			]
		}
	}, req);

	if(productRes.error) {
		return {
			status: 'error',
			error: productRes.error
		}
	}

	return productRes.data ? productRes.data.elements : false;
}
