import Link from 'next/link';
import useUserStore from '@services/stores/UserStore';
import { useTranslation } from '@tools/i18n';
import CreateWishlist from '@components/products/wishlist/CreateWishlist';

export default function WishlistActionListing(
    {
        wishlists,
        renderElement = null,
        renderButton = null,
        classes,
        wishlistType,
    },
) {
    const user = useUserStore();
    const { t } = useTranslation();
    const myNewportService = user.getService();

    function renderListings() {
        return wishlists.map((wishlist, index) => {
            return (
                <article key={index + '-' + wishlist.id} className={[ classes.item ].join(' ')}>
                    <Link href={myNewportService.createLink(`/account/lists/${wishlist.id}`)}>
                            <span>
                                <a className={'name'}>{wishlist.name}</a>
                            </span>
                    </Link>
                    {renderElement ? renderElement(wishlist) : null}
                    {renderButton ? renderButton(wishlist) : null}
                </article>
            );
        });
    }

    return <>
        {renderListings()}
        <div key={'key-actions'} className={[ classes.actionWrapper ].join(' ')}>
            <CreateWishlist type={wishlistType}></CreateWishlist>
        </div>
    </>;
}
