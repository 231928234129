import {apiFetch} from "../swClient";

function getUrl(product) {
	if(product.seoUrls) {
		return product.seoUrls[0].seoPathInfo;
	} else {
		return 'detail/' + product.id;
	}
}

export default async function getFromOption(req, optionId, parentId) {
	let productRes = await apiFetch(process.env.STOREFRONT_PRODUCT_ENDPOINT, 'post', {
		filter: [
			{
				type: "equals",
				field: "optionIds",
				value: optionId
			},
			{
				type: "equals",
				field: "parentId",
				value: parentId
			}
		],
		includes: {
			product: ["id", "seoUrls"]
		}
	}, req);

	if(productRes.error) {
		return {
			status: 'error',
			error: productRes.error
		}
	}

	return productRes.data.elements.length > 0 ? getUrl(productRes.data.elements[0]) : false;
}