import { makeStyles } from '@material-ui/styles';
import theme from '../theme';
import { useTranslation } from 'tools/i18n';
import AccountIcon from '../../public/images/icons/my-newport.svg';
import NewportDrawer from '../drawer';
import useUserStore from '../../services/stores/UserStore';
import CallToActionButton from '../products/partials/CallToActionButton';
import LoginSignupButtons from './account/LoginSignupButtons';
import If from '@components/misc/If';
import useApplicationStore from '@services/stores/ApplicationStore';

const styles = {
    loginButton: {
        ...theme.button.purchase,
        backgroundColor: theme.palette.primary.purchase.background,
        padding: '14px 12px',
        minWidth: 0,
        flexGrow: 0,
        fontSize: 10,
        textTransform: 'uppercase',
        '&:hover': {
            ...theme.button.purchaseHover,
        },
        '&:before': {
            content: '""',
            height: 1,
            backgroundColor: theme.palette.primary.background,
            display: 'block',
            position: 'absolute',
            top: 4,
            left: 0,
            right: 0,
            transition: 'all 0.25s ease',
        },
        '&:after': {
            content: '""',
            height: 1,
            backgroundColor: theme.palette.primary.background,
            display: 'block',
            position: 'absolute',
            bottom: 4,
            left: 0,
            right: 0,
            transition: 'all 0.25s ease',
        },
    },
    signupButton: {
        ...theme.button.purchase,
        backgroundColor: theme.palette.primary.purchase.background,
        padding: '14px 12px',
        minWidth: 0,
        flexGrow: 0,
        fontSize: 10,
        textTransform: 'uppercase',
        '&:hover': {
            ...theme.button.purchaseHover,
        },
        '&:before': {
            content: '""',
            height: 1,
            backgroundColor: theme.palette.primary.background,
            display: 'block',
            position: 'absolute',
            top: 4,
            left: 0,
            right: 0,
            transition: 'all 0.25s ease',
        },
        '&:after': {
            content: '""',
            height: 1,
            backgroundColor: theme.palette.primary.background,
            display: 'block',
            position: 'absolute',
            bottom: 4,
            left: 0,
            right: 0,
            transition: 'all 0.25s ease',
        },
    },
    loginWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
    },
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
    },
    menuButton: {
        justifyContent: 'flex-start',
        textTransform: 'inherit',
    },
    description: {
        marginBottom: '1rem',
    },
    trigger: {
        cursor: 'pointer',
    },
};

const useStyles = makeStyles(() => styles, { name: 'Account' });

export default function Account() {
    const { toggleDrawer } = useApplicationStore((state) => state.methods);
    const open = useApplicationStore((state) => state.modals.accountDrawerOpen);
    const user = useUserStore();
    const classes = useStyles();
    const { t } = useTranslation([ 'account' ]);
    const myNewportService = useUserStore((state) => state.getService());

    function toggle() {
        user.checkLoginStatus();
        toggleDrawer('accountDrawerOpen');
    }

    function renderLoggedIn() {
        return <section className={classes.buttonWrapper}>
            <CallToActionButton
                colorScheme={'bg-white'}
                labelPosition={'left'}
                href={myNewportService.createLink('/account')}
            >
                {t('account-link-my-page')}
            </CallToActionButton>
            <CallToActionButton
                colorScheme={'bg-white'}
                labelPosition={'left'}
                href={myNewportService.createLink('/account/order/history')}
            >
                {t('account-link-order-history')}
            </CallToActionButton>
            <CallToActionButton
                colorScheme={'bg-white'}
                labelPosition={'left'}
                href={myNewportService.createLink('/account/lists')}
            >
                {t('account-link-wishlist')}
            </CallToActionButton>
            <CallToActionButton
                colorScheme={'bg-white'}
                labelPosition={'left'}
                onClick={() => user.logout()}
            >
                {t('account-link-logout')}
            </CallToActionButton>
        </section>;
    }

    return <>
        <div className={[ classes.wrapper, theme.mode ].join(' ')}>
            <a onClick={() => toggle() } className={[ classes.trigger, 'myNewport' ].join(' ')}>
                <AccountIcon width={20} className={classes.icon} />
            </a>
        </div>
        <NewportDrawer anchor="right" open={open} close={() => toggle()}>
            <If condition={user.isLoggedIn}>
                <h3 className={classes.drawerTitle}>{t('account-login-drawer-title')}</h3>
                {renderLoggedIn()}
            </If>
            <If condition={!user.isLoggedIn}>
                <LoginSignupButtons />
            </If>
        </NewportDrawer>
    </>;
}
