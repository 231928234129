import {apiFetch} from "../swClient";

export default async function getRelatedContent(req, ids, productOverride = false, productFamily = false) {
	// Only fetch 3 content categories
	ids = ids.slice(0, 3);

	let queries = [];

	if(productOverride) {
		ids = ids.slice(0, 2);

		queries.push(
			{
				type: "equals",
				field: "seoUrls.seoPathInfo",
				value: productOverride
			}
		);
	}

	if(productFamily) {

		let salesChannelId = req.currentDomainConfig?.salesChannelId;

		if(salesChannelId) {
			queries.push(
				{
					"type": "multi",
					"operator": "and",
					"queries": [
						{
							type: "equals",
							field: "customFields.product_family",
							value: productFamily
						},
						{
							type: "equals",
							field: "seoUrls.salesChannelId",
							value: salesChannelId
						}
					]
				}
			);
		}
	}

	if(ids.length > 0) {
		queries.push(
			{
				type: "equalsAny",
				field: "id",
				value: ids
			}
		);
	}

	let inspirationRes = await apiFetch([process.env.STOREFRONT_CATEGORY_ENDPOINT].join('/'), 'post', {
		associations: {
			seoUrls: {}
		},
		filter: [
			{
				"type": "multi",
				"operator": "or",
				"queries": queries
			},
			{
				type: "equals",
				field: "active",
				value: true
			},
		],
		limit: 3
	}, req);

	if(inspirationRes.error) {
		return {
			status: 'error',
			error: inspirationRes.error
		}
	}

	return inspirationRes.data ? {
		articles: inspirationRes.data.elements.filter((article) => article.seoUrls.length > 0),
		pagination: {
			page: inspirationRes.data.page,
			limit: inspirationRes.data.limit,
			total: inspirationRes.data.total
		}
	} : false;
}