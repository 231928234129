import {apiFetch} from '../swClient';
import cache from "memory-cache";

function formatSWNavigationData(navigationData) {
	let cleanedNavigationData = [];

	if(!Array.isArray(navigationData)) {
		navigationData = Object.values(navigationData);
	}

	navigationData.forEach((menuItem) => {
		let link = '';
		let customFields = menuItem?.translated?.customFields;

		if(customFields?.hide_menu_item === true) {
			return;
		}

		if(menuItem.type === 'link') {
			if(menuItem.linkType === null) {
				return;
			}

			if(menuItem.linkType === 'external') {
				link = menuItem.externalLink;
			} else {
				if(menuItem.linkType === 'category') {
					link = menuItem.internalLink;
				}
			}
		} else {
			if(menuItem.type === 'folder' && (menuItem.children.length === 0 || !checkChildren(menuItem.children))) {
				return;
			}

			link = '/' + (menuItem.seoUrls.length > 0 ? menuItem.seoUrls[0].seoPathInfo : 'navigation/' + menuItem.id)
		}

		cleanedNavigationData.push({
			id: menuItem.id,
			parentId: menuItem.parentId,
			name: menuItem?.translated?.name || menuItem.name,
			media: menuItem?.media && menuItem?.media?.metaData ? {url: menuItem?.media?.url, width: menuItem?.media?.metaData?.width, height: menuItem?.media?.metaData?.height} : false,
			type: menuItem.type,
			url: link,
			order: menuItem?.translated?.customFields?.category_order || 0,
			hideMobile: customFields?.hide_mobile || null,
			children: formatSWNavigationData(menuItem.children).sort((a, b) => {
				if (a.order === b.order) {
					return 0;
				} else {
					return a.order > b.order ? 1 : -1
				}
			})
		});
	});

	cleanedNavigationData.sort((a, b) => {
		if (a.order === b.order) {
			return 0;
		} else {
			return a.order > b.order ? 1 : -1
		}
	});

	return cleanedNavigationData;
}

function checkChildren(children) {
	let validChild = false;

	children.forEach((child) => {
		if(child.type !== 'link' || (child.type === 'link' && child.linkType !== null)) {
			validChild = true;
		}
	});

	return validChild;
}

export default {
	get: async function (req) {
		let cachedMainMenuData = cache.get(req.currentDomainConfig.accessKey + '-main-menu');

		if(cachedMainMenuData) {
			return formatSWNavigationData(cachedMainMenuData);
		}

		const navigationBody = {
			associations: {
				seoUrls: {},
				media: {}
			},
			includes: {
				category: [
					"seoUrls", "externalLink", "name", "id", "children", "type", "linkType", "internalLink", "externalLink", "translated", "media", "parentId", "customFields"
				],
				seo_url: [
					"pathInfo", "seoPathInfo"
				]
			},
			buildTree: true,
			depth: 3
		}

		let navigationRes = await apiFetch(process.env.STOREFRONT_NAVIGATION_ENDPOINT, 'post', navigationBody, req);

		if(navigationRes.status === 'error') {
			return [];
		}

		cache.put(req.currentDomainConfig.accessKey + '-main-menu', navigationRes.data, 900000);

		return formatSWNavigationData(navigationRes.data);
	}
}
