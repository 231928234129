import { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';
import Link from 'next/link';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import theme from '../theme';
import urlNormalizer from 'tools/urlNormalization';
import { useRouter } from 'next/router';
import { TrackingContext } from '../providers/tracking';
import useSessionStore from '@services/stores/SessionStore';

const useStyles = makeStyles(
	() => {
		return {
			"@keyframes flipper": {
				"0%": {
					transform: 'translateY(100%)',
					opacity: 0,
				},
				"25%": {
					transform: 'translateY(100%)',
					opacity: 0,
				},
				"35%": {
					transform: 'translateY(0)',
					opacity: 1,
				},
				"75%": {
					transform: 'translateY(0)',
					opacity: 1,
				},
				"80%": {
					transform: 'translateY(-100%)',
					opacity: 0,
				},
				"100%": {
					transform: 'translateY(-100%)',
					opacity: 0,
				}
			},
			wrapper: {
				position: 'relative',
				width: '100%',
				overflow: 'hidden',
				minHeight: 34,
				backgroundColor: theme.palette.primary.themeColor,
				'.xmas &': {
					backgroundColor: '#112e19',
				},
			},
			link: {
				display: 'flex',
				justifyContent: 'center',
				height: 34,
				padding: '8px 0',
				textDecoration: 'none',
			},
			text: {
				position: 'absolute',
				left: 0,
				right: 0,
				margin: 0,
				fontSize: 12,
				fontWeight: 500,
				color: theme.palette.primary.background,
				textAlign: 'center',
				transform: 'translateY(100%)',
				opacity: 0,
				animation: '$flipper 10s infinite',
				'&.one': {
					animationDelay: '-2s',
				},
				'&.two': {
					animationDelay: '3s',
				},
				'&:only-child': {
					animation: 'unset',
					transform: 'unset',
					opacity: 1,
				},
			},
			countdown: {
				fontVariantNumeric: 'tabular-nums',
			}
		};
	},
	{ name: 'TopLine' }
);

export default function TopLine() {
	const classes = useStyles();
	const router = useRouter();
	const isFullwidth = useMediaQuery(theme.breakpoints.up('lg'));
	const salesChannel = useSessionStore((state) => state.session.salesChannel);
	const { actions: trackingActions } = useContext(TrackingContext);
	const [countdown, setCountdown] = useState({hasCountdown: false, currentTime: ''});

	useEffect(() => {
		if (hasCountdown(getTopLine().text)) {
			const timer = setInterval(()=> {
				const currentTime = prepareCountdown(getTopLine().text)
				if (!currentTime) {
					clearInterval(timer)
					setCountdown({
						hasCountdown: false,
						currentTime: ''
					})
				} else {
					setCountdown({
						hasCountdown: true,
						currentTime: currentTime
					})
				}
			}, 1000);
		}
	}, [salesChannel]);

	function hasCountdown(text) {
		return !!(text?.indexOf('[') >= 0 && text?.indexOf(']') >= 0)
	}

	function prepareCountdown(text) {
		const now = new Date().getTime();
		const dueDate = text.substring(text.indexOf('[') + 1, text.indexOf(']'));
		const dueTime = new Date(dueDate).getTime();
		const distance = dueTime - now;

		if (distance < 0) {
			return false
		}

		const days = Math.floor(distance / (1000 * 60 * 60 * 24));
		const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((distance % (1000 * 60)) / 1000);

		let daystring = ''
		let hourstring = ''

		if (days > 0) {
			daystring = days.toString() + 'd '
		}

		if (hours > 0) {
			hourstring = hours.toString() + 'h '
		}

		return `${daystring}${hourstring}${minutes}m ${seconds}s`
	}

	function hasTopLine() {
		const text = salesChannel && salesChannel.translated.customFields ?
			salesChannel.translated.customFields.topline_text
			: '';

		return !!text;
	}

	function hasTopLink() {
		const link =
			salesChannel && salesChannel.translated.customFields
				? salesChannel.translated.customFields.topline_link
				: '';

		return !!link;
	}

	function getTopLine() {
		const link =
			salesChannel && salesChannel.translated.customFields
				? salesChannel.translated.customFields.topline_link
				: '';
		const text =
			salesChannel && salesChannel.translated.customFields
				? salesChannel.translated.customFields.topline_text
				: '';
		const altText =
			salesChannel && salesChannel.translated.customFields
				? salesChannel.translated.customFields.topline_text_alt
				: false;
		const textColor =
			salesChannel && salesChannel.translated.customFields
				? salesChannel.translated.customFields.topline_text_color
				: '#000000';
		const bg =
			salesChannel && salesChannel.translated.customFields
				? salesChannel.translated.customFields.topline_color
				: '';

		return {
			link: link,
			text: text,
			altText: altText,
			textColor: textColor,
			background: bg
		};
	}

	function urlNormalize(url) {
		return urlNormalizer(url, router.basePath);
	}


	function renderTopBar() {
		let text = getTopLine()?.text;
		if (hasCountdown(text)) {
			text = `${text.split('[')[0]}<span className={classes.countdown}>${countdown.currentTime}</span>${text.split(']')[1]}`;
		}

		let altText = getTopLine()?.altText;
		if (hasCountdown(altText)) {
			altText = `${altText.split('[')[0]}<span className={classes.countdown}>${countdown.currentTime}</span>${altText.split(']')[1]}`;
		}

		return (
			<Container disableGutters={isFullwidth}>
				<p
					className={[classes.text, 'one'].join(' ')}
					style={{ color: getTopLine().textColor }}
					dangerouslySetInnerHTML={{ __html: text}}
				/>
				{ altText &&
					<p
						className={[classes.text, 'two'].join(' ')}
						style={{ color: getTopLine().textColor }}
						dangerouslySetInnerHTML={{ __html: altText }}
					/>
				}
			</Container>
		);
	}

	return (
		<div className={[classes.wrapper, salesChannel ? 'loaded' : ''].join(' ')}  style={{ backgroundColor: getTopLine().background }}>
			{
				hasTopLine() &&

				hasTopLink() ?
					<Link href={urlNormalize(getTopLine().link)}>
						<a className={classes.link}
						   onClick={() => trackingActions.click({ type: 'top_line', text: getTopLine().text, identifier: urlNormalize(getTopLine().link) })
						}>
							{ renderTopBar() }
						</a>
					</Link>
				:
				hasTopLine().text !== '' && renderTopBar()
			}
		</div>
	);
}
