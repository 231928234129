import { useContext, useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/styles";
import { SessionContext } from "../../providers/session";

import ExpressCheckout from "../../checkout/adyen/expressCheckout";
import { getAdyenPaymentMethods, getPaymentMethods, placeOrder } from '@tools/adyenPayment';
import theme from '../../theme'
import useCartStore from '@services/stores/CartStore';
import useSessionStore from '@services/stores/SessionStore';

const useStyles = makeStyles({
    wrapper: {
        marginTop: 25,
        padding: 15,
        border: '1px solid #d0d0d0'
    },
	expressTitle: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        padding: '0 10px',
        marginTop: -25,
        marginBottom: 10,
        fontWeight: 300,
        color: '#000',
        '& span': {
            position: 'relative',
            zIndex: 10,
            padding: '0px 10px',
            backgroundColor: theme.palette.primary.background
        }
    },
}, {name: 'ExpressWrapper'});

function ExpressWrapper({inProgress, setInProgress}) {
	const formatPrice = useSessionStore((state) => state.methods.formatPrice);
	const classes = useStyles();
	const { actions: legacySessionActions} = useContext(SessionContext);
	const cart = useCartStore((state) => state.cart);
	const [paymentSessionData, setPaymentSessionData] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);

	const expressMethodConfig = [
        'google_pay_payment_method_handler',
        'apple_pay_payment_method_handler'
    ];

	useEffect(async () => {
		if(cart.price.totalPrice > 0) {
			await prepareAdyenPaymentMethods();
			await preparePaymentMethods();
		}
	}, [cart?.price?.totalPrice]);

    async function preparePaymentMethods() {
        let paymentMethodsData = await getPaymentMethods();
		setPaymentMethods(paymentMethodsData);
    }

	async function prepareAdyenPaymentMethods() {
		let paymentMethodsData = await getAdyenPaymentMethods();
		setPaymentSessionData(paymentMethodsData);
    }

	function anyExpressMethodActive() {
		return paymentMethods?.filter((paymentMethod) => expressMethodConfig.indexOf(paymentMethod.shortName) >= 0).length > 0
	}

    function getCartTax() {
		let tax = 0;

		if(cart.price && cart.price.calculatedTaxes && cart.price.calculatedTaxes.length > 0) {
			tax = cart.price.calculatedTaxes[0].tax;
		}

		return tax;
	}

    function getShippingCost() {
		if(!cart.deliveries) {
			return 0;
		}

		let totalShippingCost = 0;
		cart.deliveries.forEach((delivery) => {
			totalShippingCost += delivery.shippingCosts.totalPrice;
		});

		return totalShippingCost;
	}

    async function prepareOrder(customer) {
		setInProgress(true);
		const customerRes = await legacySessionActions.registerCustomer(customer);

		if (customerRes.status === 'error') {
			return false
		}

		return await placeOrder();
	}

	return (
		<>
			{anyExpressMethodActive() ?
				<div className={classes.wrapper}>
					<div className={classes.expressTitle}><span>Express Checkout</span></div>

					<ExpressCheckout
						paymentMethods={paymentMethods}
						adyenPaymentMethods={paymentSessionData}
						itemCount={cart.lineItems.length}
						total={cart?.price?.totalPrice}
						tax={getCartTax()}
						shipping={formatPrice(getShippingCost())}
						setInProgress={setInProgress}
						inProgress={inProgress}
						placeOrder={async (customer) => {
							return await prepareOrder(customer);
						}}/>
				</div> : ''}
		</>
	);
}

export default ExpressWrapper;
