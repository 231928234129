import { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import { finalizePayment, getPaymentTokenFromRedirectUrl, handlePaymentRequest } from '../../../tools/adyenPayment';
import { getCartItemPrices } from '../../../functions/parseSWPrices';
import { getCountryId } from '@components/checkout/adyen/helpers/getCountryId';
import { getCountryStateId } from '@components/checkout/adyen/helpers/getCountryStateId';
import { ErrorContext } from '@components/providers/error';
import useCartStore from '../../../services/stores/CartStore';
import useSessionStore from '../../../services/stores/SessionStore';

const useStyles = makeStyles(() => ({
    wrapper: {
        flexDirection: 'column',
        justifyContent: 'stretch',
        '& > div': {
            width: '100%',
            '&:not(:first-of-type)': {
                marginTop: 10,
            },
        },
        '& .adyen-checkout__paywithgoogle': {
            '& button': {
                width: '100%',
                padding: '4px 0',
            },
        },
        '& .adyen-checkout__applepay__button': {
            width: '100%',
        },
    },
}), { name: 'ExpressCheckout' });

// @ts-ignore
export default function ExpressCheckout(
    { // @ts-ignore
        paymentMethods, // @ts-ignore
        adyenPaymentMethods, // @ts-ignore
        total, // @ts-ignore
        tax, // @ts-ignore
        shipping, // @ts-ignore
        inProgress, // @ts-ignore
        setInProgress, // @ts-ignore
        placeOrder, // @ts-ignore
    }) {
    const classes = useStyles();
    const {
        extensions,
        countries,
        shippingMethod,
        shippingLocation,
    } = useSessionStore((state) => state.session);
    const cart = useCartStore((state) => state.cart);
    const config = useSessionStore((state) => state.session.config);
    const salesChannel = useSessionStore((state) => state.session.salesChannel);
    const token = useSessionStore((state) => state.session.token);
    const [expressCustomer, setExpressCustomer] = useState(false);
    const [stateData, setStateData] = useState(false);
    const [authorizedData, setAuthorizedData] = useState(false);
    const expressMethodConfig = {
        googlepay: 'google_pay_payment_method_handler',
        applepay: 'apple_pay_payment_method_handler',
    };

    // @ts-ignore
    useEffect(async () => {
        if (token && expressCustomer && stateData && authorizedData && !inProgress) {
            setInProgress(true);

            // Place order
            let placeOrderStatus = await placeOrder(expressCustomer);
            if (!placeOrderStatus) {
                setInProgress(false);
                //TODO: NOTIFY CUSTOMER THAT PAYMENT FAILED.
                return;
            }

            let paymentToken = false;

            // @ts-ignore
            if (authorizedData?.token) {
                // @ts-ignore
                stateData.paymentMethod.googlePayToken = authorizedData.token;
                // @ts-ignore
                stateData.paymentMethod.googlePayCardNetwork = authorizedData.cardNetwork;
            }

            const domain = salesChannel?.domains?.[0]?.url || location.href;
            //Place payment
            let paymentRequest = await handlePaymentRequest(
                placeOrderStatus.orderId,
                stateData,
                domain + '/checkout/success/adyen/' + placeOrderStatus.orderId,
                domain + '/checkout/order-payment/' + placeOrderStatus.orderId,
            );

            let payment = await paymentRequest.json();
            if (payment.redirectUrl) {
                // @ts-ignore
                paymentToken = getPaymentTokenFromRedirectUrl(payment.redirectUrl);
            }

            if (!paymentToken) {
                paymentFailed(placeOrderStatus.orderId);
            }

            //Check payment status
            let paymentStatusRequest = await fetch('/api/checkout/payment-status', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    orderId: placeOrderStatus.orderId,
                }),
            });

            let paymentStatus = await paymentStatusRequest.json();
            if (paymentStatus && paymentStatus.isFinal && paymentStatus.resultCode === 'Authorised') {
                let finalizeSuccess = await finalizePayment(paymentToken);

                if (finalizeSuccess) {
                    paymentFinalized(placeOrderStatus.orderId);
                }
            } else {
                console.log(paymentStatus);
                paymentFailed(placeOrderStatus.orderId);
            }
        }
    }, [token, expressCustomer, stateData, authorizedData]);

    // @ts-ignore
    useEffect(async () => {
        if (!total || !adyenPaymentMethods) {
            return;
        }

        console.log('Updating express payments', total);

        const checkout = await AdyenCheckout({
            environment: extensions?.adyenData?.environment, // Change to 'live' for the live environment.
            clientKey: extensions?.adyenData?.clientKey, // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
            analytics: {
                enabled: true, // Set to false to not send analytics data to Adyen.
            },
            amount: {
                value: total * 100,
                currency: config.currency,
            },
            locale: config.language.languageCode,
            countryCode: shippingLocation?.country?.iso ? shippingLocation?.country?.iso : config.language.locale.toUpperCase(),
            paymentMethodsResponse: adyenPaymentMethods, // The payment session data.
        });

        let expressMethods = Object.keys(expressMethodConfig);
        expressMethods.forEach((key) => {
            const paymentMethod = checkout.create(key, getAdyenConfigObject(key));

            // @ts-ignore
            paymentMethod.isAvailable()
                .then(() => {
                    paymentMethod.mount(`#${key}-container`);
                })
                // @ts-ignore
                .catch(e => {
                    console.log(e);
                });
        });
    }, [total, adyenPaymentMethods, paymentMethods]);

    const createLineItem = (type: ApplePayJS.ApplePayLineItemType, label: string, amount: string): ApplePayJS.ApplePayLineItem => {
        return {
            amount,
            label,
            type,
        };
    };

    const createShippingMethod = (label: string, detail: string, amount: string, identifier: string): ApplePayJS.ApplePayShippingMethod => {
        return {
            label,
            detail,
            amount,
            identifier,
        };
    };

    // @ts-ignore
    function getAdyenConfigObject(methodName) {
        // @ts-ignore
        return {
            countryCode: shippingLocation?.country?.iso ? shippingLocation?.country?.iso : config.language.locale.toUpperCase(),
            callbackIntents: ['SHIPPING_ADDRESS'],
            requiredShippingContactFields: ['postalAddress', 'name', 'phone', 'email'],
            requiredBillingContactFields: ['postalAddress', 'name', 'phone', 'email'],
            shippingAddressRequired: true,
            shippingAddressParameters: {
                allowedCountryCodes: getAvailableCountries(),
                phoneNumberRequired: true,
            },
            emailRequired: true,
            // Shipping options configurations.
            shippingOptionRequired: true,
            shippingOptionParameters: {
                defaultSelectedOptionId: '018e9dbf07647233b12cd046b76de907',
                shippingOptions: [
                    {
                        id: shippingMethod?.id,
                        label: shippingMethod?.translated.name + ' ' + shipping,
                        description: shippingMethod?.translated.description,
                    },
                ],
            },

            // Only GooplePay
            transactionInfo: {
                displayItems: [
                    {
                        label: 'Subtotal',
                        type: 'SUBTOTAL',
                        price: '' + total,
                    },
                    {
                        label: 'Tax',
                        type: 'TAX',
                        price: '' + tax,
                    },
                ],
                countryCode: shippingLocation?.country?.iso ? shippingLocation?.country?.iso : config.language.locale.toUpperCase(),
                currencyCode: config.currency,
                totalPriceStatus: 'FINAL',
                totalPrice: '' + total,
                totalPriceLabel: 'Total',
            },

            paymentDataCallbacks: {
                // @ts-ignore
                onPaymentDataChanged(intermediatePaymentData) {
                    return new Promise(async resolve => {
                        resolve({});
                    });
                },
            },

            // Only ApplePay
            // @ts-ignore
            onShippingContactSelected: async (resolve, reject, event) => {
                const { countryCode } = event.shippingContact;
                const availableCountries = getAvailableCountries();
                let update: Partial<ApplePayJS.ApplePayShippingContactUpdate> = {};

                if (availableCountries.indexOf(countryCode) < 0) {
                    update = {
                        // Get the total from the application state.
                        newTotal: {
                            label: 'Newport Collection',
                            amount: total.toString(),
                        },
                        errors: [new ApplePayError('shippingContactInvalid', 'countryCode', 'Cannot ship to the selected address')],
                    };

                    resolve(update);
                }

                const newTotal = createLineItem('final', 'Newport Collection', total.toFixed(2).toString());
                const newShippingMethod = createShippingMethod( // @ts-ignore
                    shippingMethod?.translated.name, // @ts-ignore
                    shippingMethod?.translated.description, // @ts-ignore
                    cart.deliveries.length > 0 ? cart.deliveries[0].shippingCosts.totalPrice.toFixed(2).toString() : 0, // @ts-ignore
                    shippingMethod.translated.name);

                let newLineItems = cart.lineItems?.filter((item: object) => {
                    // @ts-ignore
                    return item.type !== 'promotion';
                }).map((item: object) => {
                    let prices = getCartItemPrices(item);

                    // @ts-ignore
                    return createLineItem('final', item.label, prices.price.toFixed(2).toString());
                });

                newLineItems?.push(createLineItem('final', newShippingMethod.label, newShippingMethod.amount));

                update = {
                    newShippingMethods: [newShippingMethod],
                    newLineItems: newLineItems,
                    newTotal: newTotal,
                };

                resolve(update);
            },

            // @ts-ignore
            onShippingMethodSelected: async (resolve, reject, event) => {
                const newTotal = createLineItem('final', 'Newport Collection', total.toFixed(2).toString());
                const newShippingMethod = createShippingMethod( // @ts-ignore
                    shippingMethod.translated.name, // @ts-ignore
                    shippingMethod.translated.description, // @ts-ignore
                    cart.deliveries.length > 0 ? cart.deliveries[0].shippingCosts.totalPrice.toFixed(2).toString() : 0, // @ts-ignore
                    shippingMethod.translated.name);

                let newLineItems = cart.lineItems?.filter((item: object) => {
                    // @ts-ignore
                    return item.type !== 'promotion';
                }).map((item: object) => {
                    let prices = getCartItemPrices(item);

                    // @ts-ignore
                    return createLineItem('final', item.label, prices.price.toFixed(2).toString());
                });

                newLineItems?.push(createLineItem('final', newShippingMethod.label, newShippingMethod.amount));

                const update: ApplePayJS.ApplePayShippingContactUpdate = {
                    newLineItems: newLineItems,
                    newTotal: newTotal,
                };

                resolve(update);
            },
            // @ts-ignore
            onSubmit: async (state, component) => {
                // Change payment method
                await changePaymentMethod(getPaymentMethodId(methodName));

                // Set state data
                setStateData(state.data);
            },
            // @ts-ignore
            onAuthorized: methodName === 'googlepay' ? onAuthorizedGooglePay.bind(this) : onAuthorizedApplePay.bind(this),
        };
    }

    // @ts-ignore
    async function onAuthorizedGooglePay(paymentData) {
        let nameArray = paymentData.shippingAddress.name.split(' ');
        let firstName = paymentData.shippingAddress.name;
        let lastName = paymentData.shippingAddress.name;

        if (nameArray.length > 1) {
            firstName = nameArray.slice(0, -1).join(' ');
            lastName = nameArray.slice(-1).join(' ');
        }

        let customer = {
            accountType: 'business',
            guest: true,
            acceptedDataProtection: true,
            salutationId: '9a26e5e9496749e39458042741aa18fb',
            firstName: firstName,
            lastName: lastName,
            email: paymentData.email,
            shippingAddress: await mapAddressGooglePay(paymentData.shippingAddress),
            billingAddress: await mapAddressGooglePay(paymentData.paymentMethodData?.info?.billingAddress || paymentData.shippingAddress),
        };

        let newAuthorizedData = {
            token: paymentData.paymentMethodData.tokenizationData.token,
            cardNetwork: paymentData.paymentMethodData.info.cardNetwork,
        };

        // @ts-ignore
        setExpressCustomer(customer);
        // @ts-ignore
        setAuthorizedData(newAuthorizedData);
    }

    // @ts-ignore
    async function onAuthorizedApplePay(resolve, reject, event) {
        let shippingContact = event.payment.shippingContact;
        let billingContact = event.payment.billingContact;
        let lastName = billingContact.familyName;
        let firstName = billingContact.givenName;

        let customer = {
            accountType: 'business',
            guest: true,
            acceptedDataProtection: true,
            salutationId: '9a26e5e9496749e39458042741aa18fb',
            firstName: firstName,
            lastName: lastName,
            email: shippingContact.emailAddress,
            billingAddress: await mapAddressApplePay(billingContact),
            shippingAddress: await mapAddressApplePay(shippingContact),
        };

        // @ts-ignore
        setExpressCustomer(customer);
        // @ts-ignore
        setAuthorizedData({});

        resolve(0);
    }

    // @ts-ignore
    async function mapAddressApplePay(address) {
        let lastName = address.familyName;
        let firstName = address.givenName;
        let countryId = await getCountryId(address.countryCode, countries);
        let countryStateId = await getCountryStateId(address, countryId);

        return {
            firstName: firstName,
            lastName: lastName,
            phoneNumber: address.phoneNumber,
            street: address.addressLines.join(' '),
            zipcode: address.postalCode,
            city: address.locality,
            countryId: countryId,
            countryStateId: countryStateId,
            salutationId: '9a26e5e9496749e39458042741aa18fb',
        };
    }

    // @ts-ignore
    async function mapAddressGooglePay(address) {
        let nameArray = address.name.split(' ');
        let firstName = address.name;
        let lastName = address.name;

        if (nameArray.length > 1) {
            firstName = nameArray.slice(0, -1).join(' ');
            lastName = nameArray.slice(-1).join(' ');
        }

        let countryId = await getCountryId(address.countryCode, countries);
        let countryStateId = await getCountryStateId(address, countryId);

        return {
            firstName: firstName,
            lastName: lastName,
            phoneNumber: address.phoneNumber,
            street: [address.address1, address.address2, address.address3].join(' '),
            zipcode: address.postalCode,
            city: address.locality,
            countryId: countryId,
            countryStateId: countryStateId,
            salutationId: '9a26e5e9496749e39458042741aa18fb',
        };
    }

    // @ts-ignore
    function paymentFinalized(orderId) {
        setInProgress(false);
        // @ts-ignore
        window.location = '/checkout/success/adyen/' + orderId;
    }

    // @ts-ignore
    function paymentFailed(orderId) {
        setInProgress(false);
        // @ts-ignore
        window.location = '/checkout/order-payment/' + orderId;
    }

    function getAvailableCountries() {
        // @ts-ignore
        return countries.map((country) => country.iso);
    }

    // @ts-ignore
    function getPaymentMethodId(paymentShortName) {
        // @ts-ignore
        let paymentMatches = paymentMethods.filter((payment) => payment.shortName === expressMethodConfig[paymentShortName]);

        if (paymentMatches.length > 0) {
            return paymentMatches[0].id;
        } else {
            return false;
        }
    }

    // @ts-ignore
    async function changePaymentMethod(paymentMethodId) {
        let changePaymentEndpoint = '/api/checkout/set-payment-method';

        let paymentMethodSetRequest = await fetch(changePaymentEndpoint, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                paymentMethodId,
            }),
        });

        let paymentMethodChange = await paymentMethodSetRequest.json();

        return !!(paymentMethodChange && (paymentMethodChange.status === 'success' || paymentMethodChange.success));
    }

    return (
        <div className={[classes.wrapper].join(' ')} style={{ display: paymentMethods ? 'flex' : 'none' }}>
            <div id="googlepay-container"></div>
            <div id="applepay-container"></div>
        </div>
    );
}
