import { makeStyles } from '@material-ui/styles';
import { getProductPrices } from '@functions/productOps';
import useSessionStore from '@services/stores/SessionStore';

const useStyles = makeStyles(() => ({
    oldPrice: {
        textDecorationLine: 'line-through',
        opacity: '0.5',
        fontSize: '0.7em',
        fontWeight: 500,
    },
    specialPrice: {
        color: '#e65f5f',
        fontWeight: 500,
    },
    regularPrice: {
        fontWeight: 500,
    },
    container: {
        display: 'flex',
        gap: '8px',
    },
}), { name: 'ItemPrice' });

export default function ItemPrice({product}) {
    const formatPrice = useSessionStore((state) => state.methods.formatPrice);
    const classes = useStyles();
    const prices = getProductPrices(product);

    function renderPrice(prices) {
        return <>
            <div className={classes.regularPrice}>{formatPrice(prices.price)}</div>
        </>
    }

    function renderSpecialPrice(prices) {
        return <>
            <div className={classes.specialPrice}>
                {formatPrice(prices.price)}
            </div>
            <div className={classes.oldPrice}>
                {formatPrice(prices.listPrice)}
            </div>
        </>
    }

    return <>
        <section className={classes.container}>
            {prices.isOnSale ? renderSpecialPrice(prices) : renderPrice(prices) }
        </section>
    </>;
}
