import fulfillAPIRequest from 'react-storefront/props/fulfillAPIRequest';
import getAppData from './getAppData';

export default {
	with: async function(req, getPageData) {
		return await fulfillAPIRequest(req, {
			appData: getAppData,
			pageData: getPageData,
		});
	},
	only: async function(req) {
		return await getAppData(req);
	}
}