import {apiFetch} from "../swClient";

export default async function getVariants(req, productId) {
	let productRes = await apiFetch([process.env.STOREFRONT_PRODUCT_ENDPOINT, productId].join('/'), 'post', {
		associations: {
			manufacturer: {},
			media: {
				sort: [
					{
						field: "position",
						order: "ASC",
						naturalSorting: false
					}
				]
			},
			properties: {
				associations: {
					group: {},
				}
			},
			options: {},
		},
		includes: {
			product: [
				"id",
				"translated",
				"optionIds",
				"options",
				"customFields"
			],
		}
	}, req);

	if(productRes.error) {
		return {
			status: 'error',
			error: productRes.error
		}
	}

	return productRes.data ? productRes.data : false;
}
