import appData from '../appData';
import {apiFetch} from "../swClient";

async function getCheckout(req, klarnaId) {
	let pageRes = await apiFetch(process.env.STOREFRONT_CHECKOUT_KLARNA_ORDER_ENDPOINT + '/place/' + klarnaId, 'post', {}, req);

	if(pageRes.status === 'error') {
		return {
			status: 'error'
		};
	}

	return pageRes.data;
}

export default async function checkout(req, klarnaId) {
	const data = await appData.with(req, () => getCheckout(req, klarnaId));

	return {...data};
}