import {apiFetch} from "../swClient";

export default async function getByCategory(req, categoryId, query) {
	let productRes;

	if (!query) {
		productRes = await apiFetch(['product-listing', categoryId].join('/'), 'post', {
			includes: {
				product: [
					"media",
					"productName",
					"productNumber",
					"calculatedPrice",
					"calculatedPrices",
					"cover",
					"id",
					"available",
					"availableStock",
					"translated",
					"seoUrls",
					"customFields",
					"deliveryTime",
				],
				delivery_time: [
					"name",
					"translated.name",
					"customFields"
				],
				calculated_price: [
					"unitPrice",
					"quantity",
					"listPrice",
					"regulationPrice"
				]
			},
			limit: 1
		}, req);

	} else {
		productRes = await apiFetch('search', 'post', {
			search: query,
			filter: [
				{
					type: "equals",
					field: "categoryIds",
					value: categoryId
				}
			],
			associations: {
				manufacturer: {},
				media: {
					sort: [
						{
							field: "position",
							order: "ASC",
							naturalSorting: false
						}
					]
				},
				properties: {
					associations: {
						group: {},
					}
				}
			},
			includes: {
				product: [
					"name",
					"description",
					"productName",
					"productNumber",
					"calculatedPrice",
					"calculatedPrices",
					"cover",
					"id",
					"available",
					"availableStock",
					"translated",
					"manufacturer",
					"seoUrls",
					"categoryIds",
					"customFields",
				],
				delivery_time: [
					"name",
					"translated.name"
				],
				product_media: [
					"id",
					"media"
				],
				media: [
					"thumbnails",
					"metaData",
					"url",
					"customFields",
					"translated"
				],
				media_thumbnail: [
					"url",
					"width",
					"height",
					"id"
				],
				calculated_price: [
					"unitPrice",
					"quantity",
					"listPrice",
					"regulationPrice"
				],
				product_group_option: [
					"id",
					"group",
					"translated"
				],
				product_group: [
					"id",
					"translated"
				],
				property_group_option: [
					"id",
					"groupId",
					"group",
					"name",
					"customFields",
					"translated"
				],
				property_group: [
					"id",
					"groupId",
					"name",
					"options",
					"translated",
					"customFields"
				],
				product_manufacturer: [
					"translated",
					"link",
					"id",
					"name"
				]
			}
		}, req);
	}

	if(productRes.error) {
		return {
			status: 'error',
			error: productRes.error
		}
	}

	return productRes.data || false;
}