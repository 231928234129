import {apiFetch} from '../swClient';
import cache from 'memory-cache';

function formatSWFooterData(navigationData) {
	let cleanedNavigationData = [];

	if(!Array.isArray(navigationData)) {
		navigationData = Object.values(navigationData);
	}

	navigationData.forEach((menuItem) => {
		let link;

		if(menuItem.type === 'link') {
			if(menuItem.linkType === null) {
				return;
			}

			if(menuItem.linkType === 'external') {
				link = menuItem.externalLink;
			} else {
				if(menuItem.linkType === 'category') {
					link = menuItem.internalLink;
				}
			}
		} else {
			if(menuItem.type !== 'folder') {
				link = 'detail/' + menuItem.internalLink
			} else {
				link = false;
			}
		}

		cleanedNavigationData.push({
			id: menuItem.id,
			name: menuItem?.translated?.name || menuItem.name,
			type: menuItem.type === 'link' && menuItem.linkType === 'external' ? 'external' : 'internal' ,
			url: link,
			children: formatSWFooterData(menuItem.children)
		});
	});

	return cleanedNavigationData;
}

export default {
	get: async function (req) {
		let cachedFooterData = cache.get(req.currentDomainConfig.accessKey + '-footer-menu');

		if(cachedFooterData) {
			return formatSWFooterData(cachedFooterData);
		}

		const navigationBody = {
			associations: {
				seoUrls: {}
			},
			buildTree: true,
			depth: 3
		}

		let navigationRes = await apiFetch(process.env.STOREFRONT_FOOTER_NAVIGATION_ENDPOINT, 'post', navigationBody, req);

		if(navigationRes.status === 'error') {
			return [];
		}

		cache.put(req.currentDomainConfig.accessKey + '-footer-menu', navigationRes.data, 900000);

		return formatSWFooterData(navigationRes.data);
	}
}
