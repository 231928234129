import {apiFetch} from "../swClient";

export default async function PlaceExternal(req, klarnaId, externalPaymentMethodId) {
    let pageRes = await apiFetch(process.env.STOREFRONT_CHECKOUT_KLARNA_ORDER_ENDPOINT + '/place/' + klarnaId + '/' + externalPaymentMethodId, 'post', {}, req);

	if(pageRes.status === 'error') {
		return {
			status: 'error'
		};
	}

	return pageRes;
}