export const properties = {
    keys: [
        'country',
        'decade',
        'locations',
        'getty_image_rotation',
        'designer',
        'socket',
        'in_physical_store',
        'color_filter',
        'material',
        'material_prod',
        'material_filter',
    ],
};
