import {apiFetch} from "../swClient";

export default async function getMainInspirationFeed(req, page, parentId, maxLevel = 10) {
	let inspirationRes = await apiFetch([process.env.STOREFRONT_CATEGORY_ENDPOINT].join('/'), 'post', {
		filter: [
			{
				type: "equals",
				field: "customFields.inspiration_page_show",
				value: true
			},
			{
				"type": "contains",
				"field": "path",
				"value": parentId
			},
			{
				"type": "range",
				"field": "level",
				"parameters": {
					"lte": maxLevel
				}
			}
		],
		"sort": [
			{
				"field": "updatedAt",
				"order": "desc",
				"naturalSorting": true
			}
		],
		page: page,
		limit: 9,
		"total-count-mode": 1,
		associations: {
			media: {
				sort: [
					{
						field: "position",
						order: "ASC",
						naturalSorting: false
					}
				]
			}
		},
	}, req);

	if(inspirationRes.error) {
		return {
			status: 'error',
			error: inspirationRes.error
		}
	}

	return inspirationRes.data ? {
		articles: inspirationRes.data.elements,
		pagination: {
			page: inspirationRes.data.page,
			limit: inspirationRes.data.limit,
			total: inspirationRes.data.total
		}
	} : false;
}