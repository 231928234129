import { apiFetch } from '../swClient';

export default async function getProductsBySkus(req, skus) {
	const queries = []
	skus.map(sku => {
		queries.unshift({
			type: "contains",
			field: "productNumber",
			value: sku
		});
	});
	let productRes = await apiFetch(
		[process.env.STOREFRONT_PRODUCT_ENDPOINT].join('/'),
		'post',
		{
		filter: [{
			type: 'equals',
			field: 'available',
			value: true
		}, {
				type: "multi",
				operator: "or",
				queries
			}],
			includes: {
				product: [
					'name',
					'productName',
					'productNumber',
					'customFields',
					'calculatedPrice',
					'cover',
					'parentId',
					'id',
					'available',
					'translated',
					'seoUrls',
				],
				calculated_price: ['unitPrice', 'quantity', 'listPrice']
			}
		},
		req
	);

	if (productRes.error) {
		return {
			status: 'error',
			error: productRes.error
		};
	}

	return productRes.data ? productRes.data.elements : false;
}
