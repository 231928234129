import { useTranslation } from 'tools/i18n';
import CallToActionButton from './CallToActionButton';
import { useState } from 'react';
import useCartStore from '@services/stores/CartStore';
import ShoppingBagFill from '@public/images/icons/shopping-bag-fill.svg';
import Envelope from '@public/images/icons/meddela-mig-fill.svg';

/**
 * @param {Object} product
 * @param {bool} icon
 * @param {CallToActionButton} props
 */
export default function PurchaseButton(
    {
        product,
        icon = null,
        ...props
    },
) {
    const { t } = useTranslation();
    const cartMethods =  useCartStore((state) => state.methods);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isDone, setIsDone ] = useState(false);

    function renderText(available, deliveryTime) {
        if (available) {
            return t('buy');
        }

        if (!deliveryTime?.translated?.name) {
            return t('out-of-stock');
        }

        return deliveryTime?.translated?.name;
    }

    function renderIcon(available, deliveryTime) {
        if (available) {
            return  <ShoppingBagFill fill={'transparent'} width={18} height={18} style={{margin: 6}} alt={t('buy-short')} />
        }

       return <Envelope width={18} height={18} alt={t('sold-out-subscribe')} />
    }

    async function handleAddToCart() {
        setIsLoading(true);
        try {
            await cartMethods.addToCart({ product: product.id, quantity: 1 });
            setIsDone(true);

            // shows the checkmark fo 4s after addToCart is done
            setTimeout(() => {
                setIsDone(false);
            }, 4000);
        } catch (e) {
            console.error(e);
        }
        setIsLoading(false);
    }

    return <CallToActionButton
        {...props}
        disabled={!product.available}
        onClick={handleAddToCart}
        isLoading={isLoading}
        isDone={isDone}
    >
        {icon ? renderIcon(product.available, product.deliveryTime) : renderText(product.available, product.deliveryTime)}
    </CallToActionButton>
}
