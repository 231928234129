import {apiFetch} from "../swClient";

export default async function getProductsByStore(req, store, catIds) {
	let productRes = await apiFetch([process.env.STOREFRONT_PRODUCT_ENDPOINT].join('/'), 'post', {
		associations: {
			manufacturer: {}
		},
        filter: [
            {
				"type": "range",
				"field": "customFields.store_stock_levels." + store,
				"parameters": {
					"gte": 1
				}
            },
			{
				"type": "equalsAny",
				"field": "categoryIds",
				"value": catIds
			}
        ],
		"sort": [
			{
				"field": "productNumber",
				"order": "desc",
				"naturalSorting": true
			}
		],
		includes: {
			product: [
				"media",
				"productReviews",
				"name",
				"productName",
				"productNumber",
				"calculatedPrices",
				"categoryIds",
				"customFields",
				"cover",
				"parentId",
				"id",
				"available",
				"translated",
				"optionIds",
				"properties",
				"manufacturer",
				"seoUrls",
				"seoCategory"
			],
			calculated_price: [
				"unitPrice",
				"quantity",
				"listPrice"
			],
			product_manufacturer: [
				"translated",
				"link",
				"id"
			]
		},
		limit: 4
	}, req);

	if(productRes.error) {
		return {
			status: 'error',
			error: productRes.error
		}
	}

	return productRes.data ? productRes.data.elements : false;
}