import menu from '../menu';
import footer from '../menu/footer';

export default async function getAppData(req) {
    const resolver = await Promise.all([
        await menu.get(req),
        await footer.get(req),
    ]);
    return {
        menu: resolver[0],
        footer: resolver[1],
        meta: {
            basePath: req.currentDomainConfig.basePath,
            ...req.currentDomainConfig.public
        }
    };
}
