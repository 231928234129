export function parse(params) {
    delete params._includeAppData;
    return Object.keys(params).map((key) => {
        let value = params[key];

        if (Array.isArray(value)) {
            return value;
        }

        if (value === undefined) {
            return {
                key: key,
                value: null,
                debug: params,
            };
        }

        if (typeof value === 'string') {
            let valueArray = value.split(',');
            return { key: key, value: valueArray };
        }

        return {
            key: key,
            value: value,
        };
    });
}
