import {makeStyles} from '@material-ui/styles';
import theme from "../theme";
import WeddingIcon from "../../public/images/icons/wedding.svg";
import useSessionStore from '@services/stores/SessionStore';

const styles =  {
	wrapper: {
		display: 'flex',
	},
	trigger: {
		padding: '10px 7px',
		height: 40,
	},
	icon: {
		display: 'block',
		width: 20,
		height: 20,
		color: '#000000',
		strokeWidth: 0.5,
		fill: 'transparent'
	},
};

const useStyles = makeStyles(() => styles, { name: 'WeddingIcon' });

/**
 * @note this is the WeddingIcon
 */
export default function WeddingList() {
	const classes = useStyles();
	const config = useSessionStore((state) => state.session.config);

	return (
		<>
			<div className={[classes.wrapper, theme.mode].join(' ')}>
				{config.language.locale === 'sv' &&
					<a href={`/inspiration/wishlist`} className={[classes.trigger, 'wedding'].join(' ')}>
						<WeddingIcon width={20} className={classes.icon}/>
					</a>
				}
			</div>
		</>
	)
}
