import { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import theme from '../../theme';
import { useTranslation } from '../../../tools/i18n';
import { Badge, Button, CircularProgress, Typography } from '@material-ui/core';
import Link from 'next/link';
import { ErrorContext } from '../../providers/error';
import CartIcon from '../../../public/images/icons/varukorg-thin.svg';
import ElevateUpsell from './elevateUpsell';
import NewportDrawer from '../../drawer';

import CheckoutCartItem from '../../checkout/item';
import ExpressWrapper from './expressWrapper';
import useCartStore from '@services/stores/CartStore';
import useSessionStore from '@services/stores/SessionStore';

const useStyles = makeStyles({
    '@keyframes pulse': {
        '0%': {
            opacity: 1,
        },
        '50%': {
            opacity: 0.85,
            backgroundColor: theme.palette.primary.main,
        },
        '100%': {
            opacity: 1,
        },
    },
    wrapper: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    loading: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255,255,255,0.5)',
        pointerEvents: 'none',
        userSelect: 'none',
    },
    loading__inner: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        padding: 16,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgb(255,255,255)',
    },
    trigger: {
        background: 'transparent',
        border: 'none',
        position: 'relative',
        padding: '10px 7px',
        height: 40,
        cursor: 'pointer',
    },
    icon: {
        display: 'block',
        width: 20,
        height: 20,
    },
    badge: {
        verticalAlign: 'initial',
    },
    badgeCount: {
        padding: '0 4px',
        fontSize: 11,
        background: theme.palette.header.icons.badge.background,
        color: theme.palette.header.icons.badge.color,
        minWidth: 14,
        height: 14,
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.primary.background,
        color: theme.palette.primary.color,
        width: '100%',
        '.blackfriday &': {
            border: '1px solid #fff',
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            height: '100%',
        },
    },
    content: {
        display: 'none',
        flexDirection: 'row-reverse',
        backgroundColor: theme.palette.primary.background,
        borderRadius: 3,
        boxShadow: theme.boxShadow[3],
        maxHeight: 'calc(var(--app-height) - 110px)',
        overscrollBehavior: 'none',
        '&.open': {
            display: 'flex',
            position: 'absolute',
            top: '100%',
            right: 1,
            zIndex: 1000,
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            overflowY: 'auto',
        },
    },
    main: {
        display: 'flex',
        position: 'relative',
        flexShrink: 0,
        flexWrap: 'wrap',
        width: '100%',
        color: theme.palette.primary.color,
    },
    title: {
        padding: '0',
        fontFamily: theme.typography.fontFamily,
        fontSize: 14,
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 0,
            marginTop: 8,
            marginBottom: 10,
            //textAlign: 'center'
        },
    },
    itemsWrapper: {
        width: '100%',
        '&.empty': {
            height: 'auto',
        },
    },
    items: {
        display: 'flex',
        flexDirection: 'column-reverse',
        listStyle: 'none',
        width: '100%',
        margin: 0,
        padding: 0,
        '& .empty-cart': {
            margin: '20px 0 40px',
            fontSize: 12,
            textAlign: 'center',
            color: theme.palette.text.grey,
        },
    },
    totals: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        marginTop: 'auto',
        padding: '0 0 20px',
        width: '100%',
        '&.empty': {
            display: 'none',
        },
    },
    orderSummary: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '0 0 20px',
        fontSize: 13,
        '& > div': {
            paddingTop: 8,
            paddingBottom: 3,
            '&:not(:first-of-type)': {
                borderTop: '1px solid #f0f0f0',
            },
        },
    },
    freeShipping: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        '& .label': {
            fontSize: 13,
            fontStyle: 'italic',
        },
    },
    regular: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginTop: 5,
        fontWeight: 400,
        fontSize: 13,
    },
    shipping: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginTop: 5,
        fontWeight: 400,
        fontSize: 13,
    },
    discount: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginTop: 2,
        color: '#CA0D0D',
        '& .label': {
            fontSize: 13,
        },
    },
    total: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginTop: 2,
        fontWeight: 600,
        fontSize: 13,
    },
    goToCheckout: {
        ...theme.button.purchase,
        flexGrow: 0,
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 2,
        '&.open': {
            animation: '$pulse 1s 1',
            animationDelay: 1500,
            animationTimingFunction: 'ease-in-out',
        },
        '&:hover': {
            backgroundColor: theme.button.purchaseHover.backgroundColor,
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 12,
        },
    },
    close: {
        alignSelf: 'center',
        textAlign: 'center',
        marginTop: 20,
        marginBottom: -5,
        fontSize: 13,
        color: theme.palette.text.grey,
        cursor: 'pointer',
        borderBottom: '1px solid #ababab',
        [theme.breakpoints.up('sm')]: {
            marginBottom: 0,
        },
    },
}, { name: 'Cart' });

export default function Cart() {
    const formatPrice = useSessionStore((state) => state.methods.formatPrice);
    const showExpressCheckout = useSessionStore((state) => state.methods.showExpressCheckout);
    const cart = useCartStore((state) => state.cart);
    const cartLoading = useCartStore((state) => state.loading);
    const cartMethods = useCartStore((state) => state.methods);
    const { actions } = useContext(ErrorContext);
    const [ open, setOpen ] = useState(false);
    const [ inProgress, setInProgress ] = useState(false);
    const [ cartQuantity, setCartQuantity ] = useState(0);
    const [ cartTotalDiscount, setCartTotalDiscount ] = useState(0);
    const cartElement = useRef(false);
    const { t } = useTranslation();
    const classes = useStyles();
    const allowedErrorMessages = [
        'product-stock-reached',
    ];
    const expressShippingSkus = {
        '9941': '3b41d892fd584e4b9c2259ba40922328',
        '9942': '36663c2aac7c4896acdccc02a9ffddcc',
    };

    useEffect(() => {
        const handleAddToCartEvent = (e) => {
            setOpen(true);
        };

        document.addEventListener('has-updated-cart', handleAddToCartEvent);
        document.addEventListener('add-to-cart', handleAddToCartEvent);
        return () => {
            document.removeEventListener('add-to-cart', handleAddToCartEvent);
            document.removeEventListener('has-updated-cart', handleAddToCartEvent);
        };
    }, []);

    useEffect(() => {
        setCartQuantity(cartMethods.getCartQuantity());
        setCartTotalDiscount(cartMethods.getCartDiscount());
    }, [ cart ]);

    useEffect(() => {
        if (open && cartElement?.current && typeof cartElement?.current?.scrollTo === 'function') {
            cartElement?.current?.scrollTo(0, 0);
        }
    }, [ open ]);

    useEffect(() => {
        let errors = cart.errors;
        let errorKeys = Object.keys(errors);

        errorKeys.forEach((errorKey) => {
            let error = errors[errorKey];

            if (allowedErrorMessages.indexOf(error.messageKey) < 0) {
                return;
            }

            actions.addError({
                id: error.id,
                type: 'warning',
                messageKey: error.messageKey,
                messageData: {
                    name: error.name,
                    stock: error.quantity,
                },
            });
        });
    }, [ cart.errors ]);

    function toggleCart() {
        setOpen(!open);
    }

    function getTotalCartPrice() {
        let amount = cart ? cart.price.totalPrice : 0;

        return formatPrice(amount);
    }

    function getShippingPrice() {
        if (!cart.deliveries) {
            return 0;
        }

        let totalShippingCost = 0;
        cart.deliveries.forEach((delivery) => {
            totalShippingCost += delivery.shippingCosts.totalPrice;
        });

        return totalShippingCost;
    }

    function renderCartItem(item, index) {
        if (item.type === 'promotion' || Object.keys(expressShippingSkus).indexOf(item.payload?.productNumber) >= 0) {
            return '';
        }

        return <CheckoutCartItem
            item={item}
            suspend={() => {}}
            resume={() => {}}
            isLocked={false}
            reload={() => {}}
            compact={true} key={index}
        />;
    }

    function filterPromotions(item) {
        let remove = false;

        if (item.type !== 'promotion') {
            remove = true;
        } else if (item.type === 'promotion') {
            if (item.label.indexOf('split:') >= 0) {
                remove = true;
            } else if (item.payload.discountScope === 'delivery') {
                remove = true;
            }
        }

        return remove;
    }

    function calculateRegularPrice() {
        let totalValue = 0;

        cart.lineItems.filter((item) => {
            return filterPromotions(item);
        }).forEach((lineItem) => {
            totalValue += lineItem.price.listPrice ? (lineItem.price.listPrice.price * lineItem.price.quantity) : lineItem.price.totalPrice;
        });

        return totalValue;
    }

    function renderLoading(loading) {
        if (!loading) {
            return null;
        }

        return <div className={classes.loading}>
            <div className={classes.loading__inner}>
                <CircularProgress />
                {t('updating-cart')}
            </div>
        </div>;
    }

    function renderCartContent() {
        return (
            <div className={classes.contentWrapper}>
                <div className={classes.main}>
                    <div className={classes.itemsWrapper} ref={cartElement}>
                        {renderLoading(inProgress || cartLoading)}
                        <Typography className={classes.title}>{t('your-cart')}</Typography>
                        <ul className={[ classes.items, cart.lineItems.length === 0 ? 'empty' : '' ].join(' ')}>
                            {cart.lineItems.map((item, index) => renderCartItem(item, index))}
                            {cart.lineItems.length == 0 ?
                                <Typography variant="body1" className="empty-cart">{t('empty-cart')}</Typography> : ''}
                        </ul>
                    </div>
                    <div className={[ classes.totals, cart.lineItems.length === 0 ? 'empty' : '' ].join(' ')}>
                        <div className={classes.orderSummary}>
                            <div className={classes.regular} style={{ display: 'none' }}>
                                {<span className="label">{t('regular-price')}</span>}
                                <Typography variant="inherit">{formatPrice(calculateRegularPrice())}</Typography>
                            </div>
                            <div className={classes.shipping}>
                                {<span className="label">{t('shipping')}</span>}
                                <Typography
                                    variant="inherit">{getShippingPrice() > 0 ? formatPrice(getShippingPrice()) : t('free-shipping')}</Typography>
                            </div>
                            {cartMethods.getCartDiscount() > 0 &&
                                <div className={[classes.discount].join(' ')}>
                                    <Typography className="label" variant="inherit">{t('discounts')}</Typography>
                                    <Typography className="value shipping" variant="inherit">
                                        {formatPrice(-cartMethods.getCartDiscount())}
                                    </Typography>
                                </div>
                            }
                            <div className={classes.total}>
                                {<span className="label">{t('total-sum')}</span>}
                                <Typography variant="inherit">{getTotalCartPrice()}</Typography>
                            </div>
                        </div>
                        <Link href="/checkout">
                            <Button
                                className={[ classes.goToCheckout, open ? 'open' : '' ].join(' ')}>{t('go-to-checkout')}</Button>
                        </Link>

                        {showExpressCheckout() ?
                            <ExpressWrapper inProgress={inProgress} setInProgress={setInProgress} /> :
                            <span className={classes.close}
                                  onClick={() => setOpen(false)}>{t('continue-shopping')}</span>}
                    </div>
                </div>
                <ElevateUpsell />
            </div>
        );
    }

    return (
        <>
            <div className={classes.wrapper}>
                <button className={classes.trigger} onClick={toggleCart}>
                    <Badge badgeContent={cartQuantity} classes={{ root: classes.badge, badge: classes.badgeCount }}>
                        <CartIcon className={classes.icon} />
                    </Badge>
                </button>
                <NewportDrawer anchor="right" open={open} close={toggleCart}>
                    {open ? renderCartContent() : null}
                </NewportDrawer>
            </div>
        </>
    );
}
