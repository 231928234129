import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
}, { name: 'np-form' });
export default function NewportForm({children, className = '', onSubmit}) {
    const classes = useStyles();
    return <form className={[classes.form, className].join(' ')} onSubmit={onSubmit}>
        {children}
    </form>
}
