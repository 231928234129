import {apiFetch} from "../swClient";

export default async function getProductById(req, productId) {
	let productRes = await apiFetch([process.env.STOREFRONT_PRODUCT_ENDPOINT, productId].join('/'), 'post', {
		associations: {
			manufacturer: {},
			media: {
				sort: [
					{
						field: "position",
						order: "ASC",
						naturalSorting: false
					}
				]
			},
			properties: {
				associations: {
					group: {},
				}
			},
			options: {}
		},
		includes: {
			product: [
				"media",
				"productReviews",
				"name",
				"productNumber",
				"calculatedPrice",
				"calculatedPrices",
				"cover",
				"parentId",
				"id",
				"available",
				"translated",
				"optionIds",
				"properties",
				"propertyIds",
				"options",
				"manufacturer",
				"seoUrls",
				"seoCategory",
				"customFields"
			],
			product_media: [
				"id",
				"media"
			],
			media: [
				"thumbnails",
				"metaData",
				"url"
			],
			media_thumbnail: [
				"url",
				"width",
				"height",
				"id"
			],
			calculated_price: [
				"unitPrice",
				"quantity",
				"listPrice"
			],
			property_group: [
				"id",
				"translated",
				"options"
			],
			property_group_option: [
				'id',
				'groupId',
				'group',
				'name',
				'position',
				'colorHexCode',
				'customFields',
				'translated'
			],
			product_manufacturer: [
				"translated",
				"link",
				"id"
			]
		}
	}, req);

	if(productRes.error) {
		return {
			status: 'error',
			error: productRes.error
		}
	}

	return productRes.data ? productRes.data.product : false;
}
