import {apiFetch} from "../swClient";

export default async function getBySearch(req, searchTerm) {
	let productRes = await apiFetch('search', 'post', {
		limit: 4,
		search: searchTerm,
		grouping: ["mainVariantId"],
		associations: {
			manufacturer: {},
			media: {
				sort: [
					{
						field: "position",
						order: "ASC",
						naturalSorting: false
					}
				]
			},
			properties: {
				associations: {
					group: {},
				}
			}
		},
		includes: {
			product: [
				"media",
				"productReviews",
				"name",
				"productName",
				"ratingAverage",
				"calculatedPrice",
				"calculatedPrices",
				"cover",
				"parentId",
				"id",
				"available",
				"translated",
				"optionIds",
				"properties",
				"manufacturer",
				"seoUrls",
				"seoCategory",
				"crossSellings"
			],
			product_media: [
				"id",
				"media"
			],
			media: [
				"thumbnails",
				"metaData",
				"url"
			],
			media_thumbnail: [
				"url",
				"width",
				"height",
				"id"
			],
			calculated_price: [
				"unitPrice",
				"quantity",
				"listPrice"
			],
			product_group_option: [
				"id",
				"group",
				"translated"
			],
			product_group: [
				"id",
				"translated"
			],
			property_group: [
				"id",
				"translated",
				"options"
			],
			property_group_option: [
				"translated",
				"id",
				"colorHexCode",
				"media",
				"group"
			],
			product_manufacturer: [
				"translated",
				"link",
				"id"
			]
		}
	}, req);

	if(productRes.error || !productRes.data) {
		return {
			status: 'error',
			error: productRes.error
		}
	}

	return productRes.data.elements.length > 0 ? productRes.data.elements : false;
}
