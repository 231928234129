import {apiFetch} from '../swClient';
import cache from "memory-cache";

function formatSWSessionData(sessionData) {
	let cleanedSessionData = sessionData;

	return {token: cleanedSessionData.token, sessionData: cleanedSessionData};
}

async function getSWSession(req) {
	const sessionRes = await apiFetch(process.env.STOREFRONT_SESSION_ENDPOINT, 'get', {}, req);

	return sessionRes.data;
}

async function getSWCountries(req) {
	const countryRes = await apiFetch(process.env.STOREFRONT_COUNTRY_ENDPOINT, 'post', {
		"includes": {
			"country": ["active", "id", "iso", "name", "translated"]
		}
	}, req);

	return countryRes.data;
}

async function getSWLanguage(req) {
	let cachedLanguageData = cache.get(req.currentDomainConfig.accessKey + '-languages');

	if(cachedLanguageData) {
		return cachedLanguageData;
	}

	const languageRes = await apiFetch(process.env.STOREFRONT_LANGUAGE_ENDPOINT, 'post', {}, req);

	cache.put(req.currentDomainConfig.accessKey + '-languages', languageRes.data, 1800000);

	return languageRes.data;
}

function getCurrentLanguage(languages, session) {
	const languageId = session.salesChannel.languageId;
	let currentLang = languages[0];
	let languageDataKeys = Object.keys(languages);

	languageDataKeys.forEach((key) => {
		let lang = languages[key];

		if(lang.id === languageId) {
			currentLang = lang;
		}
	});

	return {
		id: currentLang.id,
		name: currentLang.translationCode.name,
		code: currentLang.translationCode.code.split('-')[0],
		languageCode: currentLang.translationCode.code
	}
}

export default async function index(req, res) {
	const [sessionData, countryData, languageData] = await Promise.all([
		getSWSession(req),
		getSWCountries(req),
		getSWLanguage(req)
	]);

	let cleanSessionData = formatSWSessionData(sessionData);

	// Get current language from session and supported languages
	let currentLanguage = getCurrentLanguage(languageData.elements, sessionData);

	// Set Shopware token as cookie for persistens session
	res.setHeader('Set-Cookie', `${process.env.NEXT_PUBLIC_STOREFRONT_SESSION_COOKIE}=${cleanSessionData.token}; Expires=${new Date(Date.now() + (31 * 24 * 3600 * 1000))}; Path=/;`);

	// TODO: Cleanup structure & naming
	return {
		token: cleanSessionData.token,
		extensions: cleanSessionData.sessionData.extensions,
		customer: cleanSessionData.sessionData.customer,
		currentCustomerGroup: cleanSessionData.sessionData.currentCustomerGroup,
		currency: cleanSessionData.sessionData.currency,
		countries: countryData?.elements,
		salesChannel: cleanSessionData.sessionData.salesChannel,
		shippingLocation: cleanSessionData.sessionData.shippingLocation,
		paymentMethodId: cleanSessionData.sessionData.paymentMethod.id,
		paymentMethod: cleanSessionData.sessionData.paymentMethod,
		shippingMethodId: cleanSessionData.sessionData.shippingMethod.id,
		shippingMethod: cleanSessionData.sessionData.shippingMethod,
		languages: languageData.elements,
		currentLanguage: currentLanguage,
		filters: process.env.NEXT_PUBLIC_STOREFRONT_FILTER_ATTRIBUTES.split(',')
	}
}