import React, { useState, memo, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import theme from '../../theme';
import MenuItem from './menuItem';
import { useTranslation } from '../../../tools/i18n';
import { useMeasure } from 'react-use';
import { animated, useSpring, config } from 'react-spring';
import { useRouter } from 'next/router';
import { Typography } from '@material-ui/core';
import { ArrowRight, ChevronRight } from '@material-ui/icons';

const styles = {
	navigation__wrapper: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		gridColumn: '1 / 4',
		gridRow: 2,
		marginTop: 15,
		'&.open:after': {
			height: 'calc(100vh - 100%)',
			opacity: 1
		},
		borderBottom: `2px solid ${theme.palette.header.menu.highlight}`,
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},
	navigation__backdrop: {
		position: 'fixed',
		zIndex: 100,
		top: 210,
		left: 0,
		right: 0,
		height: '100vh',
		backgroundColor: 'rgba(0,0,0,0.2)',
		transition: 'opacity 0.25s ease-in',
	},
	navigation__inner: {
		width: '100%',
		maxWidth: theme.maxWidth
	},
	navigation__list: {
		display: 'flex',
		justifyContent: 'center',
		position: 'relative',
		width: '100%',
		padding: 0,
		margin: 0,
		listStyle: 'none'
	},
	navigation__item: {
		fontSize: '12px',
		'& a, & span': {
			display: 'block',
			padding: '14px 18px',
			textDecoration: 'none',
			color: theme.palette.header.menu.items.level0,
			fontFamily: theme.typography.headerFont,
			fontSize: 14
		},
		'&.level-1 > a, &.level-1 > span': {
			color: theme.palette.header.menu.items.level0,
		},
		'&.level-2 > a, &.level-2 > span': {
			color: theme.palette.header.menu.items.level2,
		},
		'&.level-2 > a': {
			color: theme.palette.header.menu.items.level2,
			fontWeight: 400
		},
		'&.level-3 > a': {
			textTransform: 'none',
			color: theme.palette.header.menu.items.level3,
			transition: 'padding 0.25s ease',
			'&:hover': {
				padding: '0 0 0 10px'
			}
		}
	},
	navigation__subAnimate: {
		position: 'absolute',
		top: '100%',
		left: 0,
		zIndex: 999,
		width: '100%',
		opacity: 0,
		overflow: 'hidden',
		backgroundColor: theme.palette.header.menu.background,
	},
	navigation__subScroll: {
		overflow: 'auto',
		maxHeight: 'calc(100vh - 190px)'
	},
	navigation__subMenu: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		background: theme.palette.header.menu.background,
	},
	navigation__subList: {
		display: 'grid',
		gridTemplateColumns: 'repeat(5, 1fr)',
		gridRowGap: 20,
		padding: '30px 0',
		margin: 0,
		width: '100%',
		maxWidth: theme.maxWidth,
		listStyle: 'none',
		[theme.breakpoints.down('lg')]: {
			padding: '30px 20px'
		},
		'&.level-2': {
			display: 'flex',
			flexDirection: 'column',
			gridRowGap: 0,
			padding: '10px 0 0'
		},
		'& li a, & li span': {
			padding: '0',
			fontSize: 13,
			lineHeight: '24px'
		},
		'&.level2-only': {
			gridTemplateColumns: '1fr',
			gridRowGap: 0,
			'& ul': {
				display: 'none'
			},
			'& a': {
				padding: 0,
				color: theme.palette.header.menu.altColor,
				textTransform: 'none'
			}
		}
	},
	burger__trigger: {
		display: 'none'
	},
	navigation__showMore: {
		display: 'flex',
		alignItems: 'center',
		lineHeight: '24px',
		fontSize: 12,
		textTransform: 'none',
		cursor: 'pointer',
		color: theme.palette.header.menu.items.level2,
	}
};

const useStyles = makeStyles(() => styles);

function DesktopMenu({ menu }) {
	const classes = useStyles();
	const router = useRouter();
	const { t } = useTranslation('general');
	const [state, setState] = useState({
		open: false,
		itemHover: false,
		subHover: false,
		submenuItems: [],
		showMore: []
	});
	const [ref, { height }] = useMeasure();

	let subAnimationProps = useSpring({
		config: {
			mass: 1,
			tension: 500,
			friction: 50,
			velocity: 30
		},
		opacity: state.open ? 1 : 0,
		height: state.open ? height : 0
	});

	useEffect(() => {
		const handleStart = url => {
			setState({ ...state, open: false });
		};

		router.events.on('routeChangeStart', handleStart);

		return () => {
			router.events.off('routeChangeStart', handleStart);
		};
	}, [router]);

	// Events bubbling up from MenuItem
	function onHoverItem(children) {
		setState(state => ({ ...state, open: true, itemHover: true, submenuItems: children }));
	}

	function onLeaveItem(e) {
		let open = state.open;

		if (state.subHover) {
			open = true;
		} else {
			open = false;
		}

		setState(state => ({ ...state, open: open, itemHover: false }));
	}

	// Events from the submenu
	function onHoverSub() {
		setState(state => ({ ...state, open: true, subHover: true }));
	}

	function onLeaveSub(e) {
		let open = state.open;

		if (state.itemHover) {
			open = true;
		} else {
			open = false;
		}

		setState(state => ({ ...state, open: open, subHover: false }));
	}

	function toggleShowMore(id) {
		let showMore = state.showMore;

		showMore.push(id);

		setState(state => ({ ...state, showMore }));
	}

	function is2Level(children) {
		let childrenHasChildren = children.length > 0 ? false : true;
		children.map((subCategory) => {
			if(subCategory.children.length > 0) {
				childrenHasChildren = true;
			}
		});

		return !childrenHasChildren;
	}

	// Renders navigation link tree
	function renderItems(items, level, parent) {
		let renderedItems = [];

		if (level === 3 && parent.type !== 'folder') {
			// console.log(parent);
			renderedItems.push(
				<MenuItem
					item={parent}
					level={level}
					classes={{
						item: classes.navigation__item,
						subMenu: classes.navigation__subMenu,
						subList: classes.navigation__subList
					}}
					open={state.open}
					label={t('see-all')}
					onHover={onHoverItem}
					onLeave={onLeaveItem}
					key={parent.id + '-' + level + '/' + 0}
				/>
			);
		}

		(items || []).forEach((item, index) => {
			if (level < 3 || (level >= 3 && item && state.showMore.indexOf(item.parentId) >= 0) || index < 8) {
				renderedItems.push(
					<MenuItem
						item={item}
						level={level}
						classes={{
							item: classes.navigation__item,
							subMenu: classes.navigation__subMenu,
							subList: classes.navigation__subList
						}}
						open={state.open}
						onHover={onHoverItem}
						onLeave={onLeaveItem}
						key={`${item.id || item.name}-${level}/${index}`}
					>
						{renderItems(item.children, level + 1, item)}
					</MenuItem>
				);
			} else if(index === 8 && items.length > 8) {
				renderedItems.push(
					<Typography className={classes.navigation__showMore}
								onClick={toggleShowMore.bind(this, parent.id)}
								key={`${index}-${item.id}`}>
						{t('show-more')}
						<ChevronRight style={{ fontSize: 16 }}/>
					</Typography>
				);
			}
		});

		return renderedItems;
	}

	function renderNav() {
		return (
			<nav className={[classes.navigation__wrapper, state.open ? 'open' : '', 'navigation'].join(' ')}>
				<div className={classes.navigation__inner}>
					<ul className={classes.navigation__list}>{renderItems(menu, 1)}</ul>
					<animated.div
						className={classes.navigation__subAnimate}
						style={subAnimationProps}
						onMouseEnter={onHoverSub}
						onMouseLeave={onLeaveSub}
					>
						<div className={classes.navigation__subScroll}>
							<div className={classes.navigation__subMenu} ref={ref}>
								{state.submenuItems && state.submenuItems.length > 0 && (
									<ul className={[classes.navigation__subList, is2Level(state.submenuItems) ? 'level2-only' : ''].join(' ')}>
										{renderItems(state.submenuItems, 2)}
									</ul>
								)}
							</div>
						</div>
					</animated.div>
				</div>
				{state.open ? (
					<div
						className={classes.navigation__backdrop}
						onMouseEnter={() => setState(state => ({ ...state, open: false, itemHover: false }))}
					></div>
				) : (
					''
				)}
			</nav>
		);
	}

	return <>{renderNav()}</>;
}

export default memo(DesktopMenu);
