import appData from '../appData';
import {apiFetch} from "../swClient";

async function getCheckout(req) {
	let pageRes = await apiFetch(process.env.STOREFRONT_CHECKOUT_KLARNA_ORDER_ENDPOINT, 'get', {}, req);

	if(pageRes.status === 'error') {
		return {
			status: 'error'
		};
	}

	return pageRes.data;
}

export default async function(req) {
	const data = await appData.with(req, () => getCheckout(req));

	return {...data};
}