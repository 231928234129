import {apiFetch} from "../swClient";

export default async function (req) {
	let cartRes = await apiFetch(process.env.STOREFRONT_COUNTRY_ENDPOINT, 'get', {}, req);

	if(cartRes.error) {
		return {
			status: 'error',
			error: cartRes.error
		}
	}

	return {
		status: 'success',
		countries: cartRes.data.elements
	}
}