import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'tools/i18n';
import AccountLogin from '@components/account/AccountLogin';

const styles = {
    accountActionWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        height: '100%',
    },
    description: {
        marginBottom: '1rem',
    },
};

const useStyles = makeStyles(() => styles, { name: 'LoginSignup' });


export default function LoginSignupButtons() {
    const classes = useStyles();
    const { t } = useTranslation([ 'account' ]);

    return <>
        <section className={classes.accountActionWrapper}>
            <AccountLogin></AccountLogin>
        </section>
    </>;
}
