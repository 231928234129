import React, {useState, memo, useEffect, useContext} from 'react';
import {makeStyles} from '@material-ui/styles';
import theme from "../../theme";
import MenuItem from "./menuItem";
import { Button, Drawer } from "@material-ui/core";
import {useTranslation} from '../../../tools/i18n';
import {useRouter} from "next/router";
import {TrackingContext} from "../../providers/tracking";
import Link from "next/link";
import CloseIcon from "../../../public/images/icons/close.svg";
import BrandsIcon from "../../../public/images/icons/brands.svg";
import CampaignsIcon from "../../../public/images/icons/campaigns.svg";
import InspirationIcon from "../../../public/images/icons/inspiration.svg";
import useSessionStore from '@services/stores/SessionStore';

const styles =  {
	navigation__wrapper: {
		display: 'flex',
		width: theme.drawerWidth,
		height: '100%',
		padding: 0,
		background: theme.palette.header.menu.background,
	},
	navigation__inner: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%'
	},
	navigation__list: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		width: '100%',
		padding: 0,
		margin: 0,
		listStyle: 'none'
	},
	navigation__item: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: 0,
		'& button, & a': {
			display: 'flex',
			width: '100%',
			minHeight: 0,
			fontSize: 14,
			fontWeight: 300,
			fontFamily: theme.typography.headerFont,
			color: theme.palette.header.menu.items.level2,
			textDecoration: 'none',
			textAlign: 'center',
		},
		'& button': {
			padding: '12px 8px 12px 12px',
		},
		'& .indicator': {
			width: 32,
			height: 32,
			margin: '-5px 0 -5px auto',
			padding: 8,
			borderRadius: 3,
			color: '#aaa',
			transform: 'rotate(-90deg)'
		},
		'&.header': {
			...theme.typography.h2,
			flexDirection: 'row',
			alignItems: 'center',
			padding: '0',
			fontSize: 24,
			fontWeight: 300,
			textTransform: 'uppercase',
			textAlign: 'center',
			borderBottom: 'none',
			'& .close': {
				color: theme.palette.header.menu.items.level2,
				position: 'absolute',
				top: 0,
				right: 0,
				marginLeft: 'auto',
				padding: 8
			}
		},
		'&.quickLinks': {
			flexDirection: 'row',
			justifyContent: 'space-between',
			padding: '12px 12px 0',
			color: theme.palette.header.menu.items.level2,
			'& .link': {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				padding: '20px 10px',
				fontSize: 12,
				fontWeight: 400,
				color: theme.palette.header.menu.items.level2,
				'& svg': {
					color: theme.palette.header.menu.items.level2,
					marginBottom: 8,
					strokeWidth: 0.4
				}
			}
		},
		'&.quickButtons': {
			display: 'flex',
			gap: 10,
			flexDirection: 'row',
			flexWrap: 'wrap',
			justifyContent: 'space-between',
			padding: '4px 12px',
			marginBottom: 20,
			'& .link': {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				padding: '12px 8px 12px 8px',
				width: 'calc(50% - 5px)',
				fontSize: 12,
				fontWeight: 400,
				color: theme.palette.header.menu.color.level2,
				textTransform: 'none',
				borderRadius: 0,
				backgroundColor: theme.palette.header.backgroundContrast,
			}
		},
		'&.opened': {
			borderBottom: 'none',
			backgroundColor: theme.palette.header.backgroundContrast,
			'& > button': {
				fontWeight: 500,
				backgroundColor: theme.palette.header.menu.backgroundContrast,
				'& svg': {
					color: theme.palette.header.menu.arrows,
					position: 'absolute',
    				right: '8px',
					transform: 'rotate(90deg)'
				}
			},
			'& .back': {
				width: 32,
				height: 32,
				padding: 8
			},
			'&.childOpen > ul > li:not(.opened)': {
				display: 'none'
			}
		},
	},
	navigation__subMenu: {
		overflow: 'hidden'
	},
	navigation__subList: {
		display: 'none',
		flexDirection: 'column',
		padding: 0,
		margin: 0,
		'.opened > &': {
			display: 'flex'
		}
	},
	navigation__spacing: {
		marginTop: 80,
		borderBottom: `1px solid ${theme.palette.header.backgroundContrast}`,
	},
	navigation__bottomMenu: {
		marginTop: 'auto',
		paddingTop: 30,
		backgroundColor: theme.palette.primary.main,
		'& $navigation__item': {
			backgroundColor: 'transparent',
			'& button': {
				color: theme.palette.primary.background,
				//fontSize: 12,
				//fontWeight: 500
			},
			'&.opened': {
				backgroundColor: theme.palette.primary.darker,
				'& > button': {
					fontWeight: 500,
					backgroundColor: theme.palette.primary.lighter,
				}
			}
		},
		'& $navigation__subList': {
			backgroundColor: 'transparent'
		}
	},
	navigation__footer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: 10,
		backgroundColor: theme.palette.primary.main,
		'& .title': {
			fontSize: 12,
			fontWeight: 300,
			color: theme.palette.primary.background,
		},
		'& ul': {
			margin: '20px 0',
			padding: 0,
			listStyle: 'none'
		},
		'& li': {
			display: 'flex',
			flexWrap: 'wrap',
			gap: 15,
			color: theme.palette.primary.background
		},
		'& a': {
			display: 'flex',
			alignItems: 'center',
			color: 'inherit',
			fontSize: 12,
			fontWeight: 400,
			textDecoration: 'none',
			'& img': {
				marginRight: 4,
				filter: 'invert()'
			}
		}
	},
	burger__trigger: {
		...theme.button.primary,
		display: 'block',
		width: 31,
		height: 31,
		color: theme.palette.primary.color,
		padding: 0,
		[theme.breakpoints.up('md')]: {
			display: 'none'
		},
		'& svg': {
			margin: 'auto',
			width: '100%'
		}
	}
}

const useStyles = makeStyles(() => styles);

function MobileMenu({menu, footer}) {
	const router = useRouter();
	const classes = useStyles();
	const [isHydrated, setHydrated] = useState(false);
	const { t } = useTranslation('general');
	const [isOpen, setOpen] = useState(false);
	const [footerMenu, setFooterMenu] = useState(false);

	const {actions: trackingActions} = useContext(TrackingContext);
	const salesChannel = useSessionStore((state) => state.session.salesChannel);

	const {
		translated: {
			customFields: {
				toplinks_outlet_text,
				toplinks_outlet_link,
				toplinks_campaign_text,
				toplinks_campaign_link,
				toplinks_news_text,
				toplinks_news_link,
				toplinks_current_campaign_text,
				toplinks_current_campaign_link,
				toplinks_current_campaign_color,
				toplinks_customerservice_text,
				toplinks_customerservice_link,
				toplinks_info_text,
				toplinks_info_link,
				toplinks_brands_text,
				toplinks_brand_link,
				mobile_menu_button_1_text,
				mobile_menu_button_1_link,
				mobile_menu_button_1_color,
				mobile_menu_button_2_text,
				mobile_menu_button_2_link,
				mobile_menu_button_2_color,
				mobile_menu_button_3_text,
				mobile_menu_button_3_link,
				mobile_menu_button_3_color
			}
		}
	} = salesChannel;

	useEffect(() => {
		if(footer && footer.length > 0) {
			let filteredFooterMenu = footer.filter((topMenuItem) => topMenuItem.name.toLowerCase() !== 'shop');
			setFooterMenu(filteredFooterMenu);
		}
	}, [footer]);

	useEffect(() => {
		const handleStart = (url) => {
			setOpen(false);
		};

		router.events.on('routeChangeStart', handleStart);

		return () => {
			router.events.off('routeChangeStart', handleStart);
		};
	}, [router]);

	// Renders navigation link tree
	function renderItems(items, level, parent) {
		let renderedItems = [];

		(items || []).filter((item) => !item.hideMobile).forEach((item, index) => {
			let currentChildren = item.children;

			const visibleChildren = item.children?.filter(child => !child.hideMobile) || [];
			if (visibleChildren.length === 1) {
				const visibleChildIndex = item.children.findIndex((child => !child.hideMobile))
				if (visibleChildIndex >= 0) {
					currentChildren = item.children[visibleChildIndex].children;
				}
			}

			renderedItems.push(
				<MenuItem
					item={item}
					level={level}
					classes={{
						item: classes.navigation__item,
						subMenu: classes.navigation__subMenu,
						subList: classes.navigation__subList,
					}}
					parent={parent}
					isMobile={true}
					key={index}
				>
					{item.children.length > 0 ? renderItems(currentChildren, level + 1, item) : ''}
				</MenuItem>
			)
		});

		return renderedItems;
	}

	// Toggles mobile menu
	function toggleMenu() {
		if(!isOpen && !isHydrated) {
			setHydrated(true);
			setOpen(true);
		} else {
			setOpen(!isOpen);
		}
	}

	// Renders navigation wrapper
	function renderNav() {
		return (
			<nav className={[classes.navigation__wrapper, isOpen ? 'open' : '', 'navigation'].join(' ')}>
				<div className={classes.navigation__inner}>
					<ul className={classes.navigation__list}>
						<li className={[classes.navigation__item, 'header'].join(' ')}>
							<CloseIcon className={'close'} onClick={() => toggleMenu()} width={32}/>
						</li>

						<li className={[classes.navigation__item, 'quickLinks'].join(' ')}>
							{toplinks_campaign_link ? <Link href={toplinks_campaign_link}>
								<a className={'link'}>
									<CampaignsIcon width={20}/>
									{toplinks_campaign_text}
								</a>
							</Link> : ''}

							{toplinks_info_link ? <Link href={toplinks_info_link}>
								<a className={'link'}>
									<InspirationIcon width={20}/>
									{toplinks_info_text}
								</a>
							</Link> : ''}

							{toplinks_news_link ? <Link href={toplinks_news_link}>
								<a className={'link'}>
									<BrandsIcon width={20}/>
									{toplinks_news_text}
								</a>
							</Link> : ''}
						</li>

						<li className={[classes.navigation__item, 'quickButtons'].join(' ')}>
							{mobile_menu_button_1_text && mobile_menu_button_1_link ? <Link href={mobile_menu_button_1_link}>
								<Button className={'link'} style={{width: '100%', backgroundColor: mobile_menu_button_1_color, color: mobile_menu_button_1_color ? theme.palette.primary.background : 'initial'}}>
									{mobile_menu_button_1_text}
								</Button>
							</Link> : ''}

							{mobile_menu_button_2_text && mobile_menu_button_2_link ? <Link href={mobile_menu_button_2_link}>
								<Button className={'link'} style={{background: mobile_menu_button_2_color, color: mobile_menu_button_2_color ? theme.palette.primary.background : 'initial'}}>
									{mobile_menu_button_2_text}
								</Button>
							</Link> : ''}

							{mobile_menu_button_3_text && mobile_menu_button_3_link ? <Link href={mobile_menu_button_3_link}>
								<Button className={'link'} style={{backgroundColor: mobile_menu_button_3_color, color: mobile_menu_button_3_color ? theme.palette.primary.background : 'initial'}}>
									{mobile_menu_button_3_text}
								</Button>
							</Link> : ''}
						</li>

						{renderItems(menu, 1, false)}

						<span style={{marginTop: 30}}></span>

						<div className={classes.navigation__bottomMenu}>
							{renderItems(footerMenu, 1, false, 'bottomMenu')}
						</div>
					</ul>

					<footer className={classes.navigation__footer}>
						<p className={'title'} style={{display: 'none'}}>{t('footer-social-media-title')}</p>

						<ul>
							<li className={['socialMedia'].join(' ')}>
								<Link href={'https://www.facebook.com/NewportHome'}>
									<a onClick={() => trackingActions.click({type: 'footer_social', text: 'Facebook', identifier: 'www.facebook.com/NewportHome'})}>
										<img alt={'Newport at Facebook'} src={'/images/fb.png'} width={24} height={24}/>
									</a>
								</Link>
								<Link href={'https://instagram.com/newporthome'}>
									<a onClick={() => trackingActions.click({type: 'footer_social', text: 'Instagram', identifier: 'www.instagram.com/newporthome'})}>
										<img alt={'Newport at Instagram'} src={'/images/insta.png'} width={24} height={24}/>
									</a>
								</Link>
								<Link href={'https://www.pinterest.se/newporthome'}>
									<a onClick={() => trackingActions.click({type: 'footer_social', text: 'Pinterest', identifier: 'www.pinterest.se/newporthome'})}>
										<img alt={'Newport at Pinterest'} src={'/images/pint.png'} width={24} height={24}/>
									</a>
								</Link>
							</li>
						</ul>
					</footer>
				</div>
			</nav>
		);
	}

	// TODO: Ta bort nyheter, outlet & varumärken

	return (
		<>
			<button className={classes.burger__trigger} onClick={() => {
				trackingActions.click({type: 'menu_open', text: t('menu'), identifier: 'burger_menu'});
				toggleMenu();
			}}>
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={0.5} d="M4 6h16M4 12h16M4 18h16" />
				</svg>
			</button>

			<Drawer anchor="left" open={isOpen} onClose={toggleMenu} ModalProps={{keepMounted: true, disablePortal: true}} style={{height: '100vh'}}>
				{isOpen ? renderNav() : null}
			</Drawer>
		</>
	)
}

export default memo(MobileMenu);
