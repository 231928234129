import {apiFetch} from "../swClient";

export default async function getCrossSell(req, productId) {
	let crosssellRes = await apiFetch([process.env.STOREFRONT_PRODUCT_ENDPOINT, productId, 'cross-selling'].join('/'), 'post', {
		associations: {
			manufacturer: {},
			media: {
				sort: [
					{
						field: "position",
						order: "ASC",
						naturalSorting: false
					}
				]
			},
			properties: {
				associations: {
					group: {},
				}
			}
		},
		includes: {
			product: [
				"media",
				"productReviews",
				"name",
				"productName",
				"productNumber",
				"ratingAverage",
				"calculatedPrice",
				"calculatedPrices",
				"cover",
				"parentId",
				"id",
				"available",
				"translated",
				"optionIds",
				"properties",
				"manufacturer",
				"customFields",
				"seoUrls",
				"seoCategory",
				"crossSellings"
			],
			product_media: [
				"id",
				"media"
			],
			media: [
				"thumbnails",
				"metaData",
				"url"
			],
			media_thumbnail: [
				"url",
				"width",
				"height",
				"id"
			],
			calculated_price: [
				"unitPrice",
				"quantity",
				"listPrice"
			],
			product_group_option: [
				"id",
				"group",
				"translated"
			],
			product_group: [
				"id",
				"translated"
			],
			property_group: [
				"id",
				"translated",
				"options"
			],
			property_group_option: [
				"translated",
				"id",
				"colorHexCode",
				"media",
				"group"
			],
			product_manufacturer: [
				"translated",
				"link",
				"id"
			]
		},
		limit: 3
	}, req);

	if(crosssellRes.error) {
		return {
			status: 'error',
			error: productRes.error
		}
	}

	let slotKeys = Object.keys(crosssellRes.data);
	let slots = [];
	slotKeys.forEach((key) => {
		slots.push(crosssellRes.data[key]);
	});

	return slots.length > 0 ? slots : false;
}
