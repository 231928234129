import {apiFetch} from './../swClient';

async function updateCustomerAddress(req, addressId, address) {
    if(addressId) {
        return await apiFetch(process.env.STOREFRONT_CUSTOMER_ADDRESS_UPDATE + '/' + addressId, 'patch', {
            ...address
        }, req);
    } else {
        return Promise.resolve();
    }
}

async function updateCustomerDetails(req, customer) {
    return await apiFetch(process.env.STOREFRONT_CUSTOMER_DETAILS_UPDATE, 'post', {
        ...customer
    }, req)
}

async function updateCustomerEmail(req, email) {
    return await apiFetch(process.env.STOREFRONT_CUSTOMER_EMAIL_UPDATE, 'post', {
        email,
        emailConfirmation: email
    }, req)
}

export default async function updateCustomer(req, email, customer, billingAddressId, billingAddress, shippingAddressId, shippingAddress) {
    const [customerBillingAddress, customerShippingAddress, customerDetails] = await Promise.all([
		updateCustomerAddress(req, billingAddressId, billingAddress),
        updateCustomerAddress(req, shippingAddressId, shippingAddress),
        updateCustomerDetails(req, customer)
	]);

    customerBillingAddress?.error?.errors.forEach((error) => {
        console.log(error.meta);
    });

	return {data: customerBillingAddress.data};
}