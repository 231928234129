import appData from "./appData";
import Page from './page';
import Session from './session';
import Register from './session/register';
import Update from './session/update';
import getCountries from './countries/get'
import setCountry from './countries/set';
import Checkout from "./checkout";
import CheckoutSuccess from "./checkout/success";
import getProductFromOption from "./product/getFromOption";
import getProductFromPartial from "./product/getFromPartial";
import getProductBySearch from "./product/getBySearch";
import getProductCrossSell from "./product/getCrossSell";
import getProductById from "./product/getById";
import getProductsByIds from "./product/getByIds";
import getProductsBySkus from "./product/getByProductSkus";
import getProductsByStore from "./product/getByStore";
import getVariants from "./product/getVariants";
import getRelatedContent from "./product/getRelatedContent";
import getMainInspirationFeed from "./inspiration/mainFeed";
import NewsletterSubscribe from "./newsletter/subscribe";
import getByCategory from "./product/getByCategory";
import PlaceExternal from "./checkout/place-external";
import getCategoryById from "./category/getById";

module.exports = {
	appData: appData,
	page: Page,
	checkout: Checkout,
	placeExternal: PlaceExternal,
	checkoutSuccess: CheckoutSuccess,
	session: Session,
	register: Register,
	update: Update,
	getCountries: getCountries,
	setCountry: setCountry,
	/* Product */
	getProductById: getProductById,
	getProductsByIds: getProductsByIds,
	getProductsBySkus: getProductsBySkus,
	getProductsByStore: getProductsByStore,
	getProductFromOption: getProductFromOption,
	getProductFromPartial: getProductFromPartial,
	getProductBySearch: getProductBySearch,
	getProductCrossSell: getProductCrossSell,
	getRelatedContent: getRelatedContent,
	getVariants: getVariants,
	getByCategory: getByCategory,
	/* Other */
	getMainInspirationFeed: getMainInspirationFeed,
	newsletterSubscribe: NewsletterSubscribe,
	getCategoryById: getCategoryById
}
