import {apiFetch} from "../swClient";

export default async function getCategoryById(req, categoryId) {
	let categoryRes = await apiFetch([process.env.STOREFRONT_CATEGORY_ENDPOINT, categoryId].join('/'), 'post', {}, req);

	if(categoryRes.error) {
		return {
			status: 'error',
			error: categoryRes.error
		}
	}

	return categoryRes.data ? categoryRes.data : false;
}