import {apiFetch} from './../swClient';

export default async function session(req, customerData) {
    var basePath = req.currentDomainConfig.basePath.split('/');
    basePath.pop();

    const sessionRes = await apiFetch(process.env.STOREFRONT_CUSTOMER_REGISTER, 'post', {
        ...customerData,
        storefrontUrl: basePath.join('/')
    }, req);

    console.log(JSON.stringify(sessionRes?.error));
    sessionRes?.error?.errors.forEach((error) => {
        console.log(error.meta);
    });

	return {data: sessionRes.data, headers: sessionRes.headers};
}