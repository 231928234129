import appData from '../appData';
import {apiFetch} from '../swClient';
import {parse} from 'tools/parseUrlParams';
import { properties } from 'tools/properties';

async function getPWAPage(slug, req, params) {
	let url = '';

	if(slug) {
		url = url + slug.join('/')
	}

	let parsedParams = parse(params);

	let preparedParams = {};
	let filterProperties = [];
	parsedParams.map((param) => {
		if (param.key === 'price') {
			preparedParams['min-' + param.key] = param.value[0];
			preparedParams['max-' + param.key] = param.value[1];
		} else if (param.key === 'page') {
			preparedParams['p'] = param.value[0];
		} else if (param.key === 'manufacturer') {
			if (param.value.length === 1) {
				preparedParams[param.key] = param.value[0];
			} else if (param.value.length > 1) {
				preparedParams[param.key] = param.value.join('|');
			}
		} else if (properties.keys.indexOf(param.key) >= 0) {
			filterProperties.push(...param.value);
		} else if (param.key === 'order') {
			preparedParams[param.key] = param.value[0];
		}
	});

	if(filterProperties.length > 0) {
		preparedParams['properties'] = filterProperties.join('|');
	}

	if(url.indexOf('shop/np-outlet') >= 0) {
		preparedParams['limit'] = 100;
	}

	let pageRes = await apiFetch(process.env.STOREFRONT_PWA_PAGE_ENDPOINT, 'post', {
		associations: {
			manufacturer: {},
			properties: {
				associations: {
					group: {}
				}
			},
			media: {
				sort: [
					{
						field: 'position',
						order: 'ASC',
						naturalSorting: false
					}
				]
			},
			options: {},
			deliveryTime: {},
		},
		includes: {
			seo_url: [
				'pathInfo',
				'seoPathInfo',
				'url'
			],
			category: [
				'id',
				'parentId',
				'childCount',
				'name',
				'metaTitle',
				'description',
				'metaDescription',
				'translated',
				'active',
				'media',
				'level',
				'seoUrls',
				'breadcrumb',
				'customFields'
			],
			property_group_option: [
				'id',
				'groupId',
				'group',
				'name',
				'position',
				'colorHexCode',
				'customFields',
				'translated'
			],
			property_group: [
				'id',
				'groupId',
				'name',
				'options',
				'translated',
				'customFields'
			],
			cms_slot: [
				'id',
				'type',
				'slot',
				'blockId',
				'config',
				'data',
				'backgroundMediaMode',
				'backgroundMedia',
				'translated',
			],
			cms_block: [
				'sectionId',
				'slots',
				'name',
				'type',
				'config',
				'cssClass',
				'backgroundColor',
				'backgroundMedia',
				'sectionPosition',
				'visibility',
				'marginTop',
				'marginBottom',
				'marginLeft',
				'marginRight',
			],
			cms_section: [
				'backgroundMedia',
				'backgroundMediaMode',
				'backgroundColor',
				'blocks',
				'cssClass',
				'type',
				'sizingMode',
				'customFields'
			],
			cms_page: [
				'id',
				'name',
				'sections',
				'type',
				'config'
			],
			product: [
				'media',
				'name',
				'description',
				'productName',
				'productNumber',
				'calculatedPrice',
				'calculatedPrices',
				'cover',
				'parentId',
				'mainVariantId',
				'shippingFree',
				'id',
				'available',
				'availableStock',
				'translated',
				'optionIds',
				'propertyIds',
				'options',
				'manufacturer',
				'properties',
				'seoUrls',
				'seoCategory',
				'categoryIds',
				'crossSellings',
				'customFields',
				'deliveryTime',
				'height',
				'length',
				'width',
				'streamIds'
			],
			delivery_time: [
				'id',
				'name',
				'translated.name',
				'customFields'
			],
			product_media: [
				'id',
				'media'
			],
			media: [
				'thumbnails',
				'metaData',
				'url',
				'customFields',
				'translated'
			],
			media_thumbnail: [
				'url',
				'width',
				'height',
				'id'
			],
			calculated_price: [
				'unitPrice',
				'quantity',
				'listPrice',
				'regulationPrice'
			],
			product_listing: [
				'sorting',
				'currentFilters',
				'elements',
				'page',
				'limit',
				'sortings',
				'availableSortings',
				'total',
				'aggregations'
			],
			product_manufacturer: [
				'translated',
				'link',
				'id',
				'name'
			]
		},
		...preparedParams,
		path: url
	}, req);

	if(pageRes.status === 'error') {
		console.log(url, pageRes?.error?.errors);
		return {
			status: 'error'
		};
	}

	return pageRes.data;
}

async function getCmsCategoryPage(slug, req, params) {
	let url = '';

	if(slug) {
		url = url + slug.join('/')
	}

	console.log('LOADING CMS / CATEGORY', url)

	let parsedParams = parse(params);
	let preparedParams = {};
	let filterProperties = [];
    let includeCustomFields = false;

	parsedParams.map((param) => {
		if (param.key === 'price') {
			preparedParams['min-' + param.key] = param.value[0];
			preparedParams['max-' + param.key] = param.value[1];
		} else if (param.key === 'page') {
			preparedParams['p'] = param.value[0];
		} else if (param.key === 'manufacturer') {
			if (param.value.length === 1) {
				preparedParams[param.key] = param.value[0];
			} else if (param.value.length > 1) {
				preparedParams[param.key] = param.value.join('|');
			}
		} else if (properties.keys.indexOf(param.key) >= 0) {
			filterProperties.push(...param.value);
		} else if (param.key === 'order') {
			preparedParams[param.key] = param.value[0];
		}
	});

	if(filterProperties.length > 0) {
		preparedParams['properties'] = filterProperties.join('|');
	}

	if(url.indexOf('shop/np-outlet') >= 0) {
		preparedParams['limit'] = 100;
	}

    /**
     * Masonry Needs CustomFields
     */
    if (url.includes('shop/slim-aarons') || url.includes('shop/gallery')) {
        includeCustomFields = true;
	}

	const payload = 	{
		associations: {
			manufacturer: {},
			deliveryTime: {},
		},
		includes: {
			seo_url: [
				'pathInfo',
				'seoPathInfo',
				'url'
			],
			category: [
				'id',
				'parentId',
				'childCount',
				'name',
				'metaTitle',
				'description',
				'metaDescription',
				'translated',
				'active',
				'media',
				'level',
				'seoUrls',
				'breadcrumb',
				'customFields'
			],
			cms_slot: [
				'id',
				'type',
				'slot',
				'blockId',
				'config',
				'data',
				'backgroundMediaMode',
				'backgroundMedia',
				'translated',
			],
			cms_block: [
				'sectionId',
				'slots',
				'name',
				'type',
				'config',
				'cssClass',
				'backgroundColor',
				'backgroundMedia',
				'sectionPosition',
				'visibility',
				'marginTop',
				'marginBottom',
				'marginLeft',
				'marginRight',
			],
			cms_section: [
				'backgroundMedia',
				'backgroundMediaMode',
				'backgroundColor',
				'blocks',
				'cssClass',
				'type',
				'sizingMode',
				'customFields'
			],
			cms_page: [
				'id',
				'name',
				'sections',
				'type',
				'config'
			],
			product: [
				'id',
				'productNumber',
				'available',
				'name',
				'cover',
				'parentId',
				'mainVariantId',
				'calculatedPrice',
				'calculatedPrices',
				'shippingFree',
				'availableStock',
				'translated',
				'manufacturer',
				'seoUrls',
				'deliveryTime',
				'propertyIds'
			],
			product_media: [
				'id',
				'media'
			],
			media: [
				'metaData',
				'url',
			],
			delivery_time: [
				'id',
				'name',
				'translated',
			],
			calculated_price: [
				'unitPrice',
				'quantity',
				'listPrice',
				'regulationPrice'
			],
			product_listing: [
				'sorting',
				'currentFilters',
				'elements',
				'page',
				'limit',
				'sortings',
				'availableSortings',
				'total',
				'aggregations'
			],
			product_manufacturer: [
				'translated',
				'link',
				'id',
				'name'
			]
		},
		...preparedParams,
		path: url
	};

    if (includeCustomFields) {
        payload.includes.product.push('customFields');
		payload.includes.media.push('customFields');
    }

	let pageRes = await apiFetch(
		process.env.STOREFRONT_PWA_PAGE_ENDPOINT,
		'post',
    	payload,
        req
    );

	if(pageRes.status === 'error') {
		console.log(url, pageRes?.error?.errors);
		return {
			status: 'error'
		};
	}

	return pageRes.data;
}

/**
 * @see slug.js
 * @todo
 */
export default async function page(req , res, slug, params, route) {
	if (route?.routeName === 'frontend.navigation.page') {
		const data = await appData.with(req, () => getCmsCategoryPage(slug, req, params));
		return {...data};
	}

	if (route?.routeName === 'frontend.detail.page') {
		const data = await appData.with(req, () => getPWAPage(slug, req, params));
		return {...data};
	}

	return {
		status: 'error',
		statusCode: 404,
		data: null,
	};

	throw 'unknown router type';
}
