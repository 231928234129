import {apiFetch} from "../swClient";

export default async function (req, country) {
	let cartRes = await apiFetch(process.env.STOREFRONT_SESSION_ENDPOINT, 'patch', {countryId: country}, req);

	if(cartRes.error) {
		return {
			status: 'error',
			error: cartRes.error
		}
	}

	return {
		status: 'success'
	}
}