import {apiFetch} from "../swClient";

export default async function (req, email) {
    var basePath = req.currentDomainConfig.basePath.split('/');
    basePath.pop();

	let newsRes = await apiFetch(process.env.STOREFRONT_NEWSLETTER_ENDPOINT + '/subscribe', 'post', {
	    email,
        option: 'direct',
        storefrontUrl: basePath.join('/')
    }, req);

	if(newsRes.status === 'error') {
		return {
			status: 'error',
			error: newsRes.type
		}
	}

	return {
		status: 'success'
	}
}