import NewportForm from '@components/forms/NewportForm';
import NewportInput from '@components/forms/NewportInput';
import CallToActionButton from '@components/products/partials/CallToActionButton';
import { useTranslation } from '@tools/i18n';
import { useState } from 'react';
import useUserStore from '@services/stores/UserStore';
import If from '@components/misc/If';
import { CircularProgress } from '@material-ui/core';

export default function AccountForgotPassword({closeHandler}) {
    const { t } = useTranslation();
    const [payload, setPayload] = useState();
    const user = useUserStore();
    const service = user.getService();
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        setLoading(true);
        const response = service.forgotPassword(payload).then((res) => {
            if (res.status === 'success') {
                setMessage(res.message);
            } else {
                const response = res.response.json().then((res) => {
                    console.log(res)
                    setMessage(res.message);
                });
            }
        }).finally(() => setLoading(false));
    }

    return <>
        <NewportForm onSubmit={handleSubmit}>
            <h1>{t('account:forgot-password')}</h1>
            <If condition={loading}>
                <CircularProgress size={32}></CircularProgress>
            </If>
            <If condition={!message}>
                <NewportInput
                    label={t('email')}
                    type={'email'}
                    onChange={(event) => {
                        setPayload(event.target.value);
                    }}
                ></NewportInput>
                <CallToActionButton
                    type={'submit'}
                    isLoading={loading}
                >
                    {t('account:send')}
                </CallToActionButton>
            </If>
            <If condition={message}>
                <p>{message}</p>
                <CallToActionButton onClick={() => closeHandler()}>
                    {t('go-back')}
                </CallToActionButton>
            </If>
        </NewportForm>
    </>
}
