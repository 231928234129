import {makeStyles} from "@material-ui/styles";
import theme from "../theme";
import {Check} from "@material-ui/icons";
import useSessionStore from '@services/stores/SessionStore';

const useStyles = makeStyles(() => {
	return {
		wrapper: {
		    display: 'flex',
            justifyContent: 'center',
		    width: '100%',
            backgroundColor: '#f1f1f1'
		},
        list: {
		    display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
		    maxWidth: theme.maxWidth,
            margin: 0,
            padding: 0
        },
        item: {
		    display: 'flex',
            alignItem: 'center',
            padding: '14px 0px',
            margin: '0 8px',
            listStyle: 'none',
            color: theme.palette.primary.color,
            fontSize: 10,
            fontWeight: 600,
            lineHeight: 1.6,
            textTransform: 'uppercase',
            letterSpacing: 0.5,
            [theme.breakpoints.up('sm')]: {
                padding: '14px 5px',
                margin: '0 15px',
            }
        },
        checkmark: {
		    marginRight: 6,
		    fontSize: 14
        }
	}
}, {name: 'CheckoutUsps'});

export default function CheckoutUsps() {
    const classes = useStyles();
    const salesChannel = useSessionStore((state) => state.session.salesChannel);

    function getUsps() {
        const uspsString = salesChannel.translated.customFields.checkout_usps;
        let uspArray = false;

        if(uspsString && uspsString.length > 0) {
            uspArray = uspsString.split('|');
        }

        return uspArray;
    }

    return (
        getUsps() ?
            <div className={classes.wrapper}>
                <ul className={classes.list}>
                    {getUsps().map((usp, index) => {
                        return <li className={classes.item} key={index}><Check className={classes.checkmark}/>{usp}</li>;
                    })}
                </ul>
            </div>
        : ''
    )
}
